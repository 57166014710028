import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppActions } from "./appStore.action"; // Adjust the path as necessary

export interface App {
  description: string;
  icon: string;
  id: string;
  is_free: boolean;
  is_installed: boolean;
  title: string;
  name: string;
}

interface AppSections {
  overview: string;
  privacy_and_security: string;
  how_to_install: string;
}
export interface WoocommerceConnect {
  redirect_url: string;
}
export interface AppDetails extends App {
  _id: {
    $oid: string;
  };
  sections: AppSections;
  images: string[];
}
interface AppState {
  data: App[];
  installedApps: App[];
  selectedApp: AppDetails | null;
  woocommerceConnect: WoocommerceConnect | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: AppState = {
  data: [],
  installedApps: [],
  selectedApp: null,
  woocommerceConnect: null,
  status: "idle",
  error: null,
};

const appSlice = createSlice({
  name: "apps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //********fetchAvailableApps*********/
      .addCase(AppActions.fetchAvailableApps.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        AppActions.fetchAvailableApps.fulfilled,
        (state, action: PayloadAction<{ data: App[]; status: boolean }>) => {
          state.status = "succeeded";
          state.data = action.payload.data;
        }
      )
      .addCase(AppActions.fetchAvailableApps.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      //********fetchInstalledApps*********/
      .addCase(AppActions.fetchInstalledApps.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        AppActions.fetchInstalledApps.fulfilled,
        (state, action: PayloadAction<{ data: App[]; status: boolean }>) => {
          state.status = "succeeded";
          state.installedApps = action.payload.data;
        }
      )
      .addCase(AppActions.fetchInstalledApps.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      //********fetchAppDetails*********/
      .addCase(AppActions.fetchAppDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        AppActions.fetchAppDetails.fulfilled,
        (
          state,
          action: PayloadAction<{ data: AppDetails; status: boolean }>
        ) => {
          state.status = "succeeded";
          state.selectedApp = action.payload.data;
        }
      )
      .addCase(AppActions.fetchAppDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      //
      .addCase(AppActions.connectWoocommerceApp.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        AppActions.connectWoocommerceApp.fulfilled,
        (
          state,
          action: PayloadAction<{ data: WoocommerceConnect; status: boolean }>
        ) => {
          state.status = "succeeded";
          state.woocommerceConnect = action.payload.data;
        }
      )
      .addCase(AppActions.connectWoocommerceApp.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export default appSlice.reducer;
