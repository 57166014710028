import React, { useEffect, useRef, useState } from 'react';
import AppReactSelectField from '../../../../AppComponents/Forms/AppFormReactSelect';
import WhatsappBroadcastResolveVaribles from '../../../Leads/contact-action/utils/WhatsappBroadcastResolveVaribles';
import { AppForm, AppFormSelectField, AppSubmitButton } from '../../../../AppComponents/Forms';
import { WhatsappActions } from '../../../../Store/Slices/Whatsapp/whatsapp.actions';
import { useAppDispatch, useAppSelector } from '../../../../Store';
import { singleWhatsappBroadcastSchema, singleWhatsappBroadcastSchemaOptInOptOut } from '../../../../Models/ValidationSchema';
import { WhatsappNetworkService } from '../../../../Store/Slices/Whatsapp/WhatsappNetworkService';
import { whatsappDataStore } from '../../../../Store/Slices/Whatsapp/whatsapp.selectors';
import { dataToTemplateFormData } from '../../../Broadcast/WhatsApp/Utils/utils';
import { InputLabel, MenuItem, Select } from '@mui/material';
import InteractiveMessageHeader from '../../../../Modules/FlowBuilder/Nodes/WhatsApp/InteractiveMessage/Header';
import InteractiveMessageBody from '../../../../Modules/FlowBuilder/Nodes/WhatsApp/InteractiveMessage/InteractiveMessageBody';
import { WhatsappSettingsActions } from '../../../../Store/Slices/Settings/WhatsappSettings/whatsappSettings.actions';
import { v4 as uuid } from "uuid";
import { convertFormToPayloadSingleWhatsappBroadcast } from '../../../Leads/contact-action/utils/constants';
import AppButton from '../../../../AppComponents/AppButton';

const ConfigModal = ({ selectConfig, show, onClose, onSave, selectAction }: any) => {

    const [messageType, setMessageType] = useState('preApproved'); // default selection
    const [body, setBody] = useState(""); // default selection
    const [headerAction, setHeaderAction] = useState<any>(""); // default selection

    const [loading, setLoading] = useState<boolean>(true);
    const formikRef = useRef<any>(null);
    const [category, setCategory] = useState<string>("")
    const [templateOptions, setTemplateOptions] = useState<any>([]);
    const dispatch = useAppDispatch();
    const { templateByIdData }: any =
        useAppSelector(whatsappDataStore);
    const [userInput, setUserInput] = useState({
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        sender_field_value: null,
        sender_field: "",
        template_id: null,
        resolved_header_variables: [],
        header_media: null,
        header_type: null,
        header_media_type: null,
        body_preview: "",
        resolved_body_variables: [],
        resolved_buttons_variables: [],
        footer_value: null,
        template_name: "",
        contact_id: "",
    });

    async function fetchData() {
        if (!templateByIdData) return;
        const data = { ...await dataToTemplateFormData(templateByIdData?.original_response), components: templateByIdData?.original_response?.components };
        formikRef?.current?.setFieldValue("template_category", data?.template_category);
        formikRef?.current?.setFieldValue("template_id", data?.template_id);
        formikRef?.current?.setFieldValue("template_language", data?.template_language);
        formikRef?.current?.setFieldValue("template_name", data?.template_name);
        formikRef?.current?.setFieldValue("template", data);
    }

    useEffect(() => {
        fetchData();
    }, [templateByIdData]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (selectConfig) {
            if (selectConfig?.response?.type === "image" || selectConfig?.response?.type === "video" || selectConfig?.response?.type === "document") {
                setBody(selectConfig?.response?.value?.text)
            } else {
                setBody(selectConfig?.response?.value)
            }
            setMessageType(selectConfig?.type === "static" ? "regular" : "preApproved")
            if (selectConfig?.type === "template") {
                // let data = {
                //     id  : selectConfig?.response?.broadcast_template_id
                // }       
                // getTemplateData(data)
                // console.log("userInput 34",userInput);

                setUserInput({
                    ...userInput,
                    template_name: selectConfig?.response?.template_name
                })
            }
            setHeaderAction({
                name: "",
                value: selectConfig?.response?.type,
                type: selectConfig?.response?.type,
                link: selectConfig?.response?.value?.url
            })
        }
    }, [selectConfig])
    const handleSave = () => {
        // Implement your save logic here
        onClose(); // Close the modal after saving
    };

    const getTemplateData = async (value: any) => {
        if (!value?.id) return;
        setLoading(true);
        dispatch(
            WhatsappActions.getTemplateById(value?.id)
        ).finally(() => {
            setLoading(false);
        });
    };

    const handleSubmitBtn = (values: any, submitProps: any) => {
        const valuesObj = {
            ...values,
            components: values?.template?.components
        };
        let payload = convertFormToPayloadSingleWhatsappBroadcast(valuesObj);
        payload = {
            ...payload,
            template_name: values?.template_name,
            template_category: values?.template_category,
            template_id: values?.template_id,
            template_language: values?.template_language
        }
        onSave(selectAction, payload, "template")
        onClose();
        // Add your submit logic here
    };

    // useEffect should always be called directly and unconditionally
    useEffect(() => {
        if (show) {
            getIntialData();
        }
    }, [show]);

    const getIntialData = () => {
        Promise.allSettled([
            WhatsappNetworkService.getWhatsappTemplatesListings({
                limit: 9999,
                offset: 0,
            }),
        ])
            .then((results) => {
                const [templatesResult] = results;
                if (templatesResult?.status === "fulfilled") {
                    const templatesData = templatesResult?.value?.data?.results?.filter(
                        (item: any) => item?.status === "APPROVED"
                    ) ?? [];
                    setTemplateOptions(templatesData ?? []);
                } else {
                    // Handle error if needed
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    if (!show) {
        return null
    }
    const handleHeaderChange = (data: any) => {
        let payload = {
            ...data,
            value: data?.type || data?.value
        }
        setHeaderAction(payload);
    };
    const handleSaveConfig = () => {
        let data;
        if (headerAction?.value === "image" || headerAction?.value === "video" || headerAction?.value === "document") {
            data = {
                "id": uuid(),
                "type": headerAction?.value,
                "value": {
                    "text": body,
                    "url": headerAction?.link
                }
            }
        } else {
            data = {
                "id": uuid(),
                "type": headerAction?.value,
                "value": body
            }
        }
        onSave(selectAction, data, "static")
        onClose();
    }
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Configure Message</h2>
                <p style={{ fontSize: "15px" }}>Send template message from one of your pre-approved templates. You can also opt to send regular message to active users.</p>
                <div className="radio-group">
                    <label style={{ display: "flex", fontSize: '16px' }}>
                        <input
                            type="radio"
                            value="preApproved"
                            checked={messageType === 'preApproved'}
                            onChange={() => setMessageType('preApproved')}
                            style={{
                                width: "38px",
                                height: "22px"
                            }}
                        />
                        Pre-approved template message
                    </label>
                    <label style={{ display: "flex", fontSize: '16px' }}>
                        <input
                            type="radio"
                            value="regular"
                            checked={messageType === 'regular'}
                            onChange={() => setMessageType('regular')}
                            style={{
                                width: "38px",
                                height: "22px"
                            }}
                        />
                        Regular Message
                    </label>
                </div>
                {selectConfig?.type === "template" ?
                    <div style={{ marginBottom: "15px" }}>
                        Selected Template :-
                        <a style={{
                            fontSize: "17px",
                            fontWeight: "500",
                            fontStyle: "oblique"
                        }}>{userInput?.template_name + "  "}</a>

                    </div>
                    : null}
                <AppForm
                    innerRef={formikRef}
                    initialValues={userInput}
                    onSubmit={(values: any, submitProps: any) => {
                        handleSubmitBtn(values, submitProps);
                    }}
                    validationSchema={singleWhatsappBroadcastSchemaOptInOptOut}
                    divStyle={{
                        flex: 1,
                    }}
                >
                    {messageType === "preApproved" ? <> <AppReactSelectField
                        menuPosition="fixed"
                        label={"Whatsapp Template"}
                        options={templateOptions ?? []}
                        valueKey={"id"}
                        displayKey="label"
                        name={"template_id"}
                        placeholder={`Select template`}
                        isClearable={true}
                        requiredSign={true}
                        isLoading={loading}
                        onChangeCallbackFn={(value: any) => {
                            getTemplateData(value);
                        }}
                        resetDependentFieldName={[
                            "resolved_header_variables",
                            "header_media",
                            "header_type",
                            "header_media_type",
                            "body_preview",
                            "resolved_body_variables",
                            "resolved_buttons_variables",
                            "footer_value",
                        ]}
                    />
                        <div style={{ height: "500px", overflow: "scroll" }}>
                            <WhatsappBroadcastResolveVaribles />
                        </div>
                        <AppSubmitButton
                            title="Save Configuration"
                            checkIsValidProperty={false}
                        ></AppSubmitButton>
                        <AppButton
                            variant="grey"
                            onClick={onClose}
                        >
                            Cancel
                        </AppButton>
                    </>
                        : null}

                </AppForm>
                {messageType === "regular" ?
                    <>
                        <InputLabel
                            shrink
                            htmlFor="footer"
                            sx={{ position: "static", transform: "none" }}
                        >
                            Header <sup style={{ color: "red" }}>*</sup>
                        </InputLabel>
                        <InteractiveMessageHeader
                            data={headerAction}
                            notAllow={["text"]}
                            isList={false}
                            characterLimit={200}
                            onChange={handleHeaderChange}
                        />
                        <InputLabel
                            shrink
                            htmlFor="footer"
                            sx={{ position: "static", transform: "none" }}
                        >
                            Body <sup style={{ color: "red" }}>*</sup>
                        </InputLabel>
                        <InteractiveMessageBody
                            text={body}
                            maxTextLimit={200}
                            onChange={(e) => { setBody(e) }}
                            error={""}
                        />
                        <AppButton
                            style={{ width: "100%",  marginBottom: "12px" }}
                            title="Save Configuration"
                            onClick={handleSaveConfig}
                        > Save Configuration </AppButton>
                        <AppButton
                            style={{ width: "100%" }}
                            variant="grey"
                            onClick={onClose}
                        >
                            Cancel
                        </AppButton>
                        {/* <div className="modal-buttons">
                            <button onClick={handleSaveConfig} style={{ backgroundColor: "rgb(33, 28, 82)" }} className="save-button">Save Configuration</button>
                            <button onClick={onClose} className="cancel-button">Cancel</button>
                        </div> */}
                    </>
                    : null}


            </div>
        </div>
    );
};

export default ConfigModal;
