// AppConfig.tsx
import React from "react";
import { useParams } from "react-router-dom";
import WoocommerceConfig from "./AppConfigComponents/WoocommerceConfig";

// Define the type of the URL params
type Params = {
  appname: "woocommerce" | "shopify"; // Define the possible types
};

const AppConfig: React.FC = () => {
  const { appname } = useParams<Params>(); // Get the type from the URL

  // Determine which component to render based on the type from the URL
  const renderComponent = () => {
    switch (appname) {
      case "woocommerce":
        return <WoocommerceConfig />;
      default:
        return <div>Invalid type</div>; // Handle default case
    }
  };

  return <div>{renderComponent()}</div>;
};

export default AppConfig;
