import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoreService } from "./appStore.service";

export class AppActions {
  static fetchAvailableApps = createAsyncThunk(
    "apps/fetchAvailableApps",
    async (_, { rejectWithValue }) => {
      try {
        const response = await CoreService.getAvailableApps();
        return response.data;
      } catch (error) {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        } else {
          return rejectWithValue("An unknown error occurred");
        }
      }
    }
  );

  static fetchInstalledApps = createAsyncThunk(
    "apps/fetchInstalledApps",
    async (_, { rejectWithValue }) => {
      try {
        const response = await CoreService.getInstalledApps();
        return response.data;
      } catch (error) {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        } else {
          return rejectWithValue("An unknown error occurred");
        }
      }
    }
  );
  static fetchAppDetails = createAsyncThunk(
    "apps/fetchAppDetails",
    async (id: string, { rejectWithValue }) => {
      try {
        const response = await CoreService.getAppDetails(id);
        return response.data;
      } catch (error) {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        } else {
          return rejectWithValue("An unknown error occurred");
        }
      }
    }
  );
  //
  static connectWoocommerceApp = createAsyncThunk(
    "apps/connectWoocommerceApp",
    async (data: any, { rejectWithValue }) => {
      try {
        const response = await CoreService.postConnectWoocommerceApp(data);
        return response.data;
      } catch (error: any) {
        if (!error.response?.data?.status) {
          return rejectWithValue(error?.response?.data);
        } else if (error instanceof Error) {
          return rejectWithValue(error.message);
        } else {
          return rejectWithValue("An unknown error occurred");
        }
      }
    }
  );
}
