import React from "react";
import { useNavigate } from "react-router-dom";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import styled from "styled-components";

const ContentTitle = styled.div`
  width: inherit;
  height: 65px;
  padding: 15px 24px !important;
  flex-direction: column;
  justify-content: center;
  /* position: absolute; */
  gap: 10px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  font-family: Inter;
  font-size: 20px;
  text-transform: capitalize;
`;

interface MenuProps {
  list: any;
}

const NavigationBar: React.FC<MenuProps> = ({ list }) => {
  const navigate = useNavigate();

  return (
    <>
      <ContentTitle>
        {list?.map((res: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <a
                onClick={() => (list?.length - 1 !== index ? navigate(-2) : "")}
                style={{ cursor: "pointer",fontSize:"18px",fontWeight: 500}}
              >
                {list?.length > 1 ? (
                  <AppMaterialIcons
                    iconName={
                      list?.length - 1 === index
                        ? "ArrowForwardIosOutlined"
                        : "ArrowBackIosOutlined"
                    }
                    style={{ fontSize: "18px", margin: "-2px 8.5px" }}
                  />
                ) : null}
                {res}
              </a>
            </React.Fragment>
          );
        })}
      </ContentTitle>
    </>
  );
};

export default NavigationBar;
