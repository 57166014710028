import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { Tabs } from "@mui/material";

export const AppDetailWrapper = styled.div`
  && {
    width: 100%;
    height: 100%;
    .container {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      padding: 1rem;
      // padding-top: 57px;
      .detail__left {
        border-radius: 4px;
        background-color: ${theme.palette.default.white};
        // min-width: calc(100vw - 833px);
        // max-width: calc(100vw - 833px);
        max-width: 100%;
        min-width: 100%;
        // flex-wrap: wrap-reverse;
        padding: 24px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        gap: 4rem;

        .left__wrapper {
          height: calc(100vh - 202px);
          overflow-y: auto;
          padding-right: 8px;
        }

        .app__data {
          display: flex;
          width: 100%;
        }

        .app__detail {
          display: flex;
          .details {
            min-width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
        .app__detail__header {
          font-size: 16px;
          font-weight: 500;
          color: ${theme.palette.default.black};
          margin-bottom: 10px;
        }
        .app__desc {
          max-width: 374px;
          font-size: 14px;
          color: ${theme.palette.default.darkGrey};
        }
        .install__btn {
          margin-top: 30px;
          display: flex;
          align-items: center;
          gap: 8px;
          button {
            border: 1px solid ${theme.palette.default.RightBorderDivder};
            border-radius: 4px;
            padding: 5px;
          }
        }
        .app__images {
          width: calc(100% - 374px);
          // min-width: 531px;
          .slider__wrapper {
            // min-width: 531px;
            .slick-slide img {
              margin: 0 auto;
              min-height: 314px;
            }
          }
        }

        .app__info {
          width: 100%;

          .description {
            .app__detail__header {
              font-size: 14px;
              font-weight: 500;
              color: ${theme.palette.default.black};
              margin-bottom: 10px;
              text-align: left;
              margin-top: 24px;
            }
            .app__desc__text {
              font-size: 14px;
              color: ${theme.palette.default.darkGrey};
              text-align: left;
            }
          }
        }
      }
      .detail__right {
        max-width: 360px;
        width: 100%;
      }
    }
  }
`;

export const AppDetailTabs = styled(Tabs)`
  && {
    // border: 2px solid blue;
    border-bottom: 1px solid rgba(126, 131, 146, 0.2);
    margin: 0 1rem;
    button {
      text-transform: capitalize;
      font-size: 0.875rem;
    }
    .Mui-selected {
      border-bottom: 3px solid;
    }
  }
`;

export const ReportIssueModalWrapper = styled.div`
  && {
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 22px;
      height: 63px;
      border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
      .title {
        font-size: 16px;
        font-weight: 500;
        color: ${theme.palette.default.black};
      }
    }
    .body {
      padding: 16px 22px;
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid ${theme.palette.default.BorderColorGrey};
      padding: 16px 22px;
      gap: 10px;
    }
  }
`;

export const HtmlContentWrapper = styled('div')`
  && {
    padding: 16px 22px;
    color: ${theme.palette.default.darkGrey};
    text-align: left; // Align text to the left

    h1, h2, h3, h4, h5, h6 {
      color: ${theme.palette.default.black};
    }
  }
`;
export const AppStoreWrapper = styled.div`
  && {
    height: 100%;
    .body {
      padding-bottom: 1rem;
      overflow-y: auto;
      // height: calc(100% - 57px);
      .app__info {
        border-radius: 4px;
        margin: 0 1rem;
        background-color: ${theme.palette.default.white};
        margin-top:10px;
        padding: 24px;
        padding-right: 8px;
        padding-left: 12px;
        .heading {
          margin-bottom: 24px;
          .heading__text {
            font-size: 18px;
            font-weight: 500;
            color: ${theme.palette.default.black};
          }
          .heading__desc {
            font-size: 14px;
            font-weight: 400;
            color: ${theme.palette.default.darkGrey};
          }
        }
        .all__apps {
          padding-right: 16px;
          padding-top: 14px;
          padding-left: 12px;
        }
      }
    }
    .main__header {
      padding: 0 24px;
      padding-top: 12px;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header__left {
        min-width: calc(100vw - 789px);
        display: flex;
        flex-direction: column;
        gap: 48px;
        .header__text {
          color: ${theme.palette.default.black};
          font-size: 40px;
          font-weight: 500;
          max-width: 626px;
          line-height: 48px;
        }
      }
      .header__right {
        display: flex;
        align-items: center;
        justify-content: center;
        .header__img {
          width: 100%;
          img {
            width: 100%;
            min-height: 269px;
          }
        }
      }
    }
  }
`;

export const SearchInput = styled.div`
  && {
    max-width: 881px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      width: 100%;
      height: 48px;
      border-radius: 30px;
      padding: 0 16px;
      font-size: 16px;
      border: 1px solid #cbcdd3;
    }
    svg {
      position: absolute;
      right: 16px;
      cursor: pointer;
    }
  }
`;

export const AppsCard = styled.div`
  && {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 4px;
    min-height: 180px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .isFree {
        font-size: 12px;
        background-color: ${theme.palette.default.teaGreen};
        border: 1px solid ${theme.palette.default.success};
        color: ${theme.palette.default.success};
        border-radius: 30px;
        min-width: 48px;
        min-height: 17px;
        text-align: center;
      }
      .isPaid {
        font-size: 12px;
        background-color: ${theme.palette.default.bisque};
        border: 1px solid ${theme.palette.default.error};
        color: ${theme.palette.default.error};
        border-radius: 30px;
        min-width: 48px;
        min-height: 17px;
        text-align: center;
      }
    }
    .middle {
      font-size: 14px;
      font-weight: 500;
      color: ${theme.palette.default.black};
      margin-bottom: 10px;
    }
    .bottom {
      font-size: 12px;
      font-weight: 400;
      color: ${theme.palette.default.darkGrey};
    }
    &:hover {
      cursor: pointer !important;
      box-shadow: 0px 0px 20px 0px rgba(91, 115, 232, 0.3);
      .isFree,
      .top,
      .isPaid,
      .middle,
      .bottom {
        cursor: pointer !important;
      }
    }
  }
`;
