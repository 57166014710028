import React, { useRef } from "react";
import Slider from "react-slick";
import { Dialog, DialogContent, Box } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as NextArrow } from "../../../assets/images/icons/CarouselRight.svg";
import { ReactComponent as PrevArrow } from "../../../assets/images/icons/CarouselLeft.svg";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  .slick-next {
    right: -100px !important;
    width: 80px !important;
    height: 80px !important;
    z-index: 1 !important;
  }
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%; /* Ensure the container has a defined height */
}

// .slick-list .slick-track {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%); /* Centers content vertically and horizontally */
// }
  .slick-prev {
    left: -100px !important;
    width: 80px !important;
    height: 80px !important;
    z-index: 1 !important;
  }
`;

interface ImageSliderModalProps {
  open: boolean;
  onClose: () => void;
  images: string[];
}

const ImageSliderModal: React.FC<ImageSliderModalProps> = ({
  open,
  onClose,
  images,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleClose = (e: React.MouseEvent) => {
    if (sliderRef.current && !sliderRef.current.contains(e.target as Node)) {
      onClose();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow style={{ width: 80, height: 80 }} />,
    prevArrow: <PrevArrow style={{ width: 80, height: 80 }} />,
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      //   PaperProps={{
      //     style: { backgroundColor: "rgba(0, 0, 0, 0.08)", boxShadow: "none" },
      //   }}
      PaperProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          boxShadow: "none",
          height: "100vh",
          width: "100vw",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        },
      }}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
          onClick: handleClose,
        },
      }}
    >
      <GlobalStyle />
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: 0,
          position: "relative",
          overflow: "hidden",
        }}
        onClick={handleClose}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: "blur(5px)",
            zIndex: 0,
          }}
        />
        <Box
          ref={sliderRef}
          style={{
            width: "100%",
            maxWidth: "800px",
            margin: "0 auto",
            position: "relative",
            zIndex: 1,
          }}
        >
          <Slider {...settings}>
            {images.map((img, index) => (
              <div key={index} >
                <img
                  src={img}
                  alt={`Slide ${index}`}
                  style={{ width: "100%"}}
                />
              </div>
            ))}
          </Slider>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageSliderModal;
