import React, { useState } from "react";
import { Box, Tab, Alert } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import NavigationBar from "../../NavigationBar";
import {
  AppDetailTabs,
  AppStoreWrapper,
} from "../../AppDetail/AppDetailStyles";
import AppInput from "../../../../AppComponents/ui/app-input";
import { Label } from "../../../../Customization/CommonElements";
import AppButton from "../../../../AppComponents/AppButton";
import { AppActions } from "../../../../Store/Slices/AppStore/appStore.action";
import { useAppDispatch } from "../../../../Store";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";

// Validation schema
const validationSchema = Yup.object().shape({
  siteName: Yup.string()
    .matches(
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)?[a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/i,
      "Invalid URL"
    )
    .required("Woocommerce store URL is required"),
});

const WoocommerceConfig: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [userId, setUserId] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();

  // Handle tab change
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  // Handle form submission
  const handleSubmit = (payload: { siteName: string }) => {
    dispatch(AppActions.connectWoocommerceApp(payload))
      .then((res: any) => {
        if (res.payload.status) {
          let url = res.payload.data.redirect_url;
          const width = 800;
          const height = 600;

          // Calculate the position for centering the window
          const left = (window.screen.width - width) / 2;
          const top = (window.screen.height - height) / 2;

          // Open the URL in a new centered window
          window.open(
            url,
            "_blank",
            `width=${width},height=${height},top=${top},left=${left}`
          );

          // Listen for the message from the new window
          const handlePostMessage = (event: MessageEvent) => {
            if (event.data?.userId || event.data?.success) {
              // Update state with received data
              setUserId(event.data.userId);
              setSuccess(event.data.success);
            }
          };

          window.addEventListener("message", handlePostMessage);

          // Cleanup event listener when component unmounts
          return () => {
            window.removeEventListener("message", handlePostMessage);
          };
        } else {
          showAlert(res?.payload?.error, "error");
        }
      })
      .catch((err) => {
        showAlert("Something went wrong", "error");
      });
  };

  // Extract query parameters from the URL

  // Determine the message and severity for the Alert
  let alertMessage = "";
  let alertSeverity: "success" | "error" = "success";

  if (success == "1") {
    alertMessage = `Success! Your store ${userId} is successfully integrated with Geta.ai`;
    alertSeverity = "success";
  } else if (success == "0") {
    alertMessage = "Failed to complete the integration. Please try again.";
    alertSeverity = "error";
  }

  return (
    <AppStoreWrapper>
      <NavigationBar list={["App Store", "Woocommerce", "Configurations"]} />

      <Box className="body">
        <Box className="app__info">
          <AppDetailTabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="app tabs"
          >
            <Tab label="Settings" />
          </AppDetailTabs>
          {success === "0" || success === "1" ? (
            <Alert
              style={{
                width: "fit-content",
                padding: "5px",
                margin: "10px 0",
              }}
              severity={alertSeverity}
            >
              {alertMessage}
            </Alert>
          ) : (
            <Box sx={{ flexGrow: 1, height: "100%" }} className="all__apps">
              <h2>Default Auth Parameters</h2>

              <Formik
                initialValues={{ siteName: "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Label>
                      Please enter your Woocommerce store URL.
                      <sup style={{ color: "red" }}>*</sup>
                    </Label>
                    <Field
                      name="siteName"
                      as={AppInput}
                      placeholder="example.com"
                      error={touched.siteName && !!errors.siteName}
                      helperText={touched.siteName && errors.siteName}
                    />
                    {touched.siteName && !!errors.siteName && (
                      <Label style={{ color: "red" }}>{errors.siteName}.</Label>
                    )}
                    <Label>
                      If your woocommerce store url is{" "}
                      <b>https://example.com/wp-admin/</b> or{" "}
                      <b>https://example.com</b> please enter <b>example.com</b>
                    </Label>
                    <Alert
                      style={{
                        width: "fit-content",
                        padding: "5px",
                        margin: "10px 0",
                      }}
                      severity="info"
                    >
                      For integration to work seamlessly, make sure you're an
                      admin in Wordpress.
                    </Alert>

                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <AppButton type="submit" variant="primarydark">
                        Continue
                      </AppButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          )}
        </Box>
      </Box>
    </AppStoreWrapper>
  );
};

export default WoocommerceConfig;
