import React, { useEffect } from "react";

const RedirectPage: React.FC = () => {
  useEffect(() => {
    // Extract query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get("user_id");
    const success = queryParams.get("success");

    // Send the extracted data to the original window
    if (window.opener) {
      window.opener.postMessage({ userId, success }, "*");
    }

    // Optionally, close the current window after sending the message
    window.close();
  }, []);

  return (
    <div>
      <h2>Processing...</h2>
      <p>You will be redirected shortly.</p>
    </div>
  );
};

export default RedirectPage;
