import { AxiosResponse } from "axios";
import { App, AppDetails,WoocommerceConnect } from "./appStore.slice"; // Adjust the path as necessary

import { coreAxios } from "../../../Utils";
import { getHttpService } from "../../../Services";
import { APPSTORE_ENDPOINTS } from "../../../Services/Apis/Endpoints";

export class CoreService {
  static httpService = getHttpService(coreAxios);

  static getAvailableApps = (): Promise<
    AxiosResponse<{ data: App[]; status: boolean }>
  > => {
    return CoreService.httpService.get({
      url: APPSTORE_ENDPOINTS.AVAILABLE_APPS,
    });
  };

  static getInstalledApps = (): Promise<
    AxiosResponse<{ data: App[]; status: boolean }>
  > => {
    return CoreService.httpService.get({
      url: `${APPSTORE_ENDPOINTS.AVAILABLE_APPS}?installed=true`,
    });
  };
  static getAppDetails = (
    id: string
  ): Promise<AxiosResponse<{ data: AppDetails; status: boolean }>> => {
    return CoreService.httpService.get({
      url: `${APPSTORE_ENDPOINTS.APP_DETAILS}/${id}`,
    });
  };

  // ************** Woocommerce apis *******************
  static postConnectWoocommerceApp = (
    data: any
  ): Promise<AxiosResponse<{ data: WoocommerceConnect; status: boolean }>> => {
    return CoreService.httpService.post({
      url: `${APPSTORE_ENDPOINTS.WOOCOMMERCE_CONNECT}`,data
    });
  };

  //****************************************************
}
