import React, { useEffect } from "react";
import { Avatar, Box, IconButton, Tab } from "@mui/material";
import Slider from "react-slick";
import {
  AppDetailTabs,
  AppDetailWrapper,
  HtmlContentWrapper,
} from "./AppDetailStyles";
import AppButton from "../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import useModal from "../../../Hooks/useModel/useModel";
import NavigationBar from "../NavigationBar";
import CarouselModal from "./AppCarouselModal";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { AppActions } from "../../../Store/Slices/AppStore/appStore.action";
import {
  selectAppStatus,
  selectSelectedApp,
  selectAppError,
} from "../../../Store/Slices/AppStore/appStore.selector";

const AppDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>(); // Get app ID from URL

  const appDetail = useAppSelector(selectSelectedApp);
  const appStatus = useAppSelector(selectAppStatus);
  const appError = useAppSelector(selectAppError);

  const [index, setIndex] = React.useState(0);
  const { isShowing: isCarouselModalOpen, toggle: toggleCarouselModal } =
    useModal();

  useEffect(() => {
    if (id) {
      dispatch(AppActions.fetchAppDetails(id));
    }
  }, [id, dispatch]);

  const onTabClicked = (event: React.SyntheticEvent, newIndex: number) => {
    setIndex(newIndex);
  };

  if (appStatus === "loading") {
    return <div>Loading...</div>;
  }

  if (appStatus === "failed") {
    return <div>Error: {appError}</div>;
  }
  return (
    <AppDetailWrapper>
      <NavigationBar list={["App Store", appDetail?.title]} />
      <Box className="container">
        <Box className="detail__left">
          <Box className="left__wrapper">
            <Box className="app__data" display="flex" flexWrap="wrap">
              <Box className="app__detail" flex="1" minWidth="50%">
                <Box className="details">
                  <Avatar
                    slotProps={{
                      img: {
                        style: { objectFit: "scale-down", padding: "5px" },
                      },
                    }}
                    alt="Image 1"
                    src={appDetail?.icon}
                  />
                  <Box className="app__detail__header">{appDetail?.title}</Box>
                  <Box className="app__desc">{appDetail?.description}</Box>
                  <Box className="install__btn">
                    <AppButton
                      onClick={() =>
                        navigate(`/app-store/install/${appDetail?.name}`)
                      }
                      variant="primarydark"
                    >
                      Install
                    </AppButton>
                    <IconButton>
                      <AppMaterialIcons iconName="SettingsOutlined" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              <Box className="app__images" flex="1" minWidth="50%">
                <Box className="slider__wrapper">
                  <Slider
                    arrows={false}
                    dots={true}
                    infinite={false}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    {appDetail?.images.map(
                      (
                        i: string | undefined,
                        index: React.Key | null | undefined
                      ) => (
                        <Box className="slide" key={index}>
                          <img
                            onClick={() => toggleCarouselModal()}
                            src={i}
                            alt={`slide-${index}`}
                          />
                        </Box>
                      )
                    )}
                  </Slider>
                  <CarouselModal
                    open={isCarouselModalOpen}
                    onClose={toggleCarouselModal}
                    images={appDetail?.images || []}
                  />
                </Box>
              </Box>
            </Box>

            <Box className="app__info">
              <AppDetailTabs value={index} onChange={onTabClicked}>
                <Tab label="Overview" />
                <Tab label="How to Install" />
                <Tab label="Privacy and Security" />
              </AppDetailTabs>
              <Box className="body">
                <HtmlContentWrapper
                  dangerouslySetInnerHTML={{
                    __html:
                      index === 0
                        ? appDetail?.sections?.overview
                        : index === 1
                        ? appDetail?.sections?.how_to_install
                        : appDetail?.sections?.privacy_and_security,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </AppDetailWrapper>
  );
};

export default AppDetail;
