export enum API_ENDPOINTS {
  CORE_API = "core_api",
  CH_BOT_API = "ch_bot_api",
  CH_CONSOLE_API = "ch_console_api",
}

export class CH_BOT_ENDPOINTS {
  static HISTORY = "/workspace/get-history";
  static QUERY = "/conversation/query";
  static METRIC_COUNT = "/console/analytics/count-metrics";
  static USER_GROWTH = "/console/analytics/user-growth-chart";
  static UNIQUE_USER_GROWTH = "/console/analytics/unique-user-growth-chart";
  static CONVERSION_RATE_GROWTH =
    "/console/analytics/conversion-rate-growth-chart";
  static RETURNING_LEADS_GROWTH =
    "/console/analytics/returning-leads-growth-chart";
  static ENGAGEMENT_RATE_GROWTH =
    "/console/analytics/engagement-rate-growth-chart";
  static AVERAGE_BOT_DURATION_GROWTH =
    "/console/analytics/average-duration-growth-chart";
  static TIME_SAVED_GROWTH = "/console/analytics/time-saved-growth-chart";
  static COMMON_INTENTS = "/console/analytics/intent-analysis";
  static ACTIVE_HOURS = "/console/analytics/active-hours";
  // static METRIC_COUNT = "/console/analytics/count-metrics";
  // static USER_GROWTH = "/console/analytics/user-growth-chart";
  // static UNIQUE_USER_GROWTH = "/console/analytics/unique-user-growth-chart";
  // static CONVERSION_RATE_GROWTH =
  // "/console/analytics/conversion-rate-growth-chart";
  // static RETURNING_LEADS_GROWTH =
  // "/console/analytics/returning-leads-growth-chart";
  // static ENGAGEMENT_RATE_GROWTH =
  // "/console/analytics/engagement-rate-growth-chart";
  // static AVERAGE_BOT_DURATION_GROWTH =
  // "/console/analytics/average-duration-growth-chart";
  // static TIME_SAVED_GROWTH = "/console/analytics/time-saved-growth-chart";
  // static COMMON_INTENTS = "/console/analytics/intent-analysis";
  // static ACTIVE_HOURS = "/console/analytics/active-hours";

  // static LEADS = "/console/get-leads";

  // contacts endpoints==========
  static CONTACTS = "/workspace/contact-list";
  static CONTACTS_DEATAILS = "/workspace/contact";
  static ADDCONTACTS = "/workspace/contacts/contact-form";

  static LEADSCOLUMNS = "/console/get-leads-filter";
  static DOWNLOADLEADS = "console/download-leads";
  static SESSIONS_DEVICES = "/console/analytics/device-info";

  // static LEADSCOLUMNS = "/console/get-leads-filter";
  // static DOWNLOADLEADS = "console/download-leads";
  // static SESSIONS_DEVICES = "/console/analytics/device-info";

  static LEADS = "/workspace/get-leads";
  // static LEADSCOLUMNS = "/console/get-leads-filter";
  // static DOWNLOADLEADS = "console/download-leads";
  // static SESSIONS_DEVICES = "/console/analytics/device-info";

  static VISITOR_ID = "/conversation/save_visitor_id";
  static FAQ_LIST = "/console/faq";
  static NOT_ANSWERED_FAQ_LIST = "/console/not-answered-faqs";
  static CREATE_FAQ_QUESTION = "/console/create-faq";
  static CREATE_NOT_ANSWERED_FAQ_QUESTION =
    "/console/non-faq/add-to-variations";
  static FAQ_QUESTION_DETAIL = "/console/faq/{0}";
  static NOt_ANSWEREDFAQ_QUESTION_DETAIL = "/console/non-faq/{0}";
  static FLOW_LIST = "/console/list-flows";
  static FAQ_VARIATIONS = "/console/phrases/{0}";
  static SEARCH_FAQ_VARIATIONS = "/console/variations/suggest";
  static LOCATIONS = "/workspace/analytics/locations";
  static BROWSERS = "/workspace/analytics/browser-language";
  static MAIN_FLOW_LIST = "/workspace/analytics/main-flow";
  static SUB_FLOW_LIST = "/workspace/analytics/sub-flow";
  static LINK_SUB_FLOW = "/console/flow/{0}";

  static GENERATE_PHRASES = "/console/generate-phrases";
  static GET_PHRASES = "/console/phrases/{0}";
  static PUBLISH_BOT = "/console/publish-bot";
}

export class CORE_ENDPOINTS {
  static AUTH_LOGIN = "/auth/login/";
  static AUTH_LOGOUT = "/auth/logout/";
  static AUTH_RESET = "/auth/reset-password/";
  static AUTH_NEW_PASSWORD = "/auth/change-password/";

  static AUTH_BUSINESS_SIGNUP = "/auth/business/signup/";
  static AUTH_TOKEN_VERIFY = "/auth/token/verify/";
  static AUTH_SWITCH_ACC = "/auth/account/switch/";
  static PROJECTS = "/core/project/";
  static PROJECT_VER_CONFIG = "/core/project-version/{0}/configuration/";

  static PLANS = "/core/plans/";
  // static CREDITS = "/core/credits";
  static CREDITS = "/core/credits";
  static START_SUBSCRIPTION = "/core/subscription/start/";
  static UPGRADE_SUBSCRIPTION = "/core/subscription/upgrade/";
  static FINALIZE_SUBSCRIPTION = "/core/subscription/finalize/";
  static ACTIVE_SUBSCRIPTIONS = "/core/subscription/active/";
  static SSO_MOCK_LOGIN = "/auth/login/sso/";

  static ACCOUNT_VERIFY = "/auth/account/verify/";

  //*whatsapp
  static WHATSAPP_TEMPLATE_OPTIONS = "/core/whatsapp-template-options";
}

export class CH_CONSOLE_ENDPOINTS {
  static CHATBOT_UPLOAD_FILE = "/chatbot/attachment/";
  static PUBLIC_UPLOAD_FILE = "/workspace/whatsapp-resolve-media-attach";
  static CHATBOT_SETUP = "/chatbot/setup/";
  static CHATBOT_UPDATE_WORKSPACE = "/chatbot/update-workspace/";
  static CHATBOT_BY_WORKSPACE_ID = "/chatbot/chatbot-workspace-by-id/";
  static CHATBOTS = "/chatbot/?limit=50&offset=0";
  static CHATBOT_CATEGORY = "/chatbot/category/";
  static CHATBOT_TYPE = "/chatbot/bot-type/";
  static CHATBOT_SINGLE = "/chatbot/{0}/";
  static CHATBOT_PERSISTENT_UPDATE = "/chatbot/{0}/{1}";
  static CHATBOT_CONFIGURATION_UPDATE = "/chatbot/{0}/configuration/update/";
  static LANDING_PAGE_BOT_DATA = "/chatbot/get-configuration-by-botid/{0}/";
  static CHATBOT_SINGLE_CONFIGURATION = "/chatbot/configuration/{0}/";
  static CHATBOT_BY_BOT_ID = "/chatbot/get-by-bot-id/";
  static CHATBOT_TRACKING_CODE = "/chatbot/{0}/tracking-code/";
  static CHATBOT_THEME_UPDATE = "/chatbot/{0}/theme/update/";

  static CHATBOT_ZOHO_OAUTH_REDIRECT =
    "/chatbot/zoho-integrations/zoho-crm-oauth-redirect";
  static CHATBOT_ZOHO_FIELDS_GET =
    "/chatbot/zoho-integrations/zoho-crm-get-fields";
  static CHATBOT_ZOHO_CONFIG_SAVE = "/chatbot/zoho-integrations/zoho-crm-save";
  static CHATBOT_ZOHO_FIELDS_UPDATE =
    "/chatbot/zoho-integrations/zoho-crm-field-mapping-update";
  static CHATBOT_ZOHO_LOGOUT = "/chatbot/zoho-integrations/zoho-logout";
  static CHATBOT_ZOHO_GET_FIELD_DATA =
    "/chatbot/zoho-integrations/get_zoho_crm_field_data";
  static CHATBOT_GOOGLE_SHEET_OAUTH_REDIRECT =
    "/chatbot/googlesheet-integrations/google-sheet-oauth-redirect";
  static CHATBOT_GOOGLE_SHEET_CONFIG_SAVE =
    "/chatbot/googlesheet-integrations/google-sheet-save";
  static CHATBOT_GOOGLE_LOGOUT =
    "/chatbot/googlesheet-integrations/google-logout";
  static CHATBOT_CREATED_GOOGLE_SHEET =
    "/chatbot/googlesheet-integrations/get-google-sheet";
  static CHATBOT_FACEBOOK_GET =
    "/chatbot/facebook-integrations/facebook-get-user-id";
  static CHATBOT_FACEBOOK_SAVE = "/chatbot/facebook-integrations/facebook-save";
  static CHATBOT_FACEBOOK_LOGOUT =
    "/chatbot/facebook-integrations/facebook-logout";
  static CHATBOT_INSTAGRAM_GET =
    "/chatbot/instagram-integrations/instagram-get-data";
  static CHATBOT_INSTAGRAM_REDIRECT =
    "/chatbot/instagram-integrations/instagram-oauth-redirect";
  static CHATBOT_INSTAGRAM_SAVE =
    "/chatbot/instagram-integrations/instagram-save";
  static CHATBOT_INSTAGRAM_LOGOUT =
    "/chatbot/instagram-integrations/instagram-logout";

  //agents
  static CHATBOT_AGENT = "/chatbot/agent/";
  static CHATBOT_AGENT_VERIFY = "/agent/verify/";
  static CHATBOT_AGENT_UPDATE = "/chatbot/agent/{0}/";
  static CHATBOT_DEPARTMENT = "/chatbot/department/";
  static CHATBOT_DEPARTMENT_UPDATE = "/chatbot/department/{0}/";
  static CHATBOT_ROLE = "/chatbot/group/";

  static CHATBOT_LANGUAGES = "/chatbot/languages/";

  static CHATBOT_LOOK_FEEL = "/chatbot/{0}/theme/get/";

  //*whatsapp
  static WHATSAPP_ATTACHMENT_RESOLVED =
    "/workspace/whatsapp-resolve-media-attach";
  static WHATSAPP_BROADCAST_LISTINGS = "/workspace/all-whatsapp-broadcast";
  static WHATSAPP_BROADCAST_DETAILS_LISTINGS =
    "/workspace/whatsapp-broadcastById/{0}";

  //new endpoints for node microservice
  static WHATSAPP_BROADCAST_DELETE_TEMPLATE =
    "/workspace/broadcast/delete-whatsapp-templateById/{0}";
  static WHATSAPP_TEMPLATE_CREATE =
    "/workspace/broadcast/whatsapp-template/create";
  static GET_DEFAULT_TEMPLATE =
    "/core/default-template";
  // Email

  //Dashboard
  static DASHBOARD_USERS = "/workspace/analytics/Users";

  //Agent Online Status
  static AGENT_ONLINE_STATUS = "/workspace/agent-online-status";

  //new endpoints for node microservice
  static WHATSAPP_BROADCAST_LISTINGS_DOWNLOAD =
    "/workspace/broadcast/download-broadcast-data";
  static EMAIL_BROADCAST_LISTINGS_DOWNLOAD =
    "/workspace/broadcast/download-broadcast-data";
  static EMAIL_ATTACHMENT = "/workspace/broadcast/whatsapp-media-attach";
  static WHATSAPP_ATTACHMENT = "/workspace/broadcast/whatsapp-media-attach";
  static WHATSAPP_TEMPLATE_LISTINGS =
    "/workspace/broadcast/whatsapp-template-list";
  static WHATSAPP_TEMPLATE_BY_ID =
    "/workspace/broadcast/whatsapp-templateById/{0}";
  static WHATSAPP_TEMPLATE_DATA_BY_ID =
    "/workspace/broadcast/whatsapp-templateById/";
  static WHATSAPP_APP_SYNC = "/workspace/broadcast/sync-whatsapp-template "
}

export class CHATBOT_CELERY_ENDPOINTS {
  static CELERYTASKS = "/conversation/tasks/";
}

export class MATOMO_ENDPOINTS {
  static HOME = "/";
}
export class PARAPHRASES_ENDPOINTS {
  static ALL_LANGUAGE_TRANSLATE = "/language/translate";
  static TRAIN_BOT = "/nlp/train";
}

export class LIVE_CHAT_ENDPOINTS {
  static DEPARTMENTS = "/{0}/departments/";
}

export class FLOW_BUILDER_ENDPOINTS {
  static CREATE_NODE = "/console/create-node";
  static UPDATE_NODE = "/console/create-node";
  static DELETE_NODE = "/console/create-node";

  static CREATE_FLOW = "/console/create-flow";
  static UPDATE_FLOW = "/console/flow/{0}";
  static DELETE_FLOW = "/console/flow/{0}";
  static GET_FLOWS = "/console/list-flows";
  static GET_FLOW = "/console/flow/{0}";

  static UPDATE_NODE_STAGE = "/console/flow-update-stages";

  static WHATSAPP_USER_CONSENT = "/console/settings/whatsapp-user-consent";
}

export class CHATBOT_CONVERSATION_ENDPOINTS {
  static HISTORY = "/conversation/chat-history";
  static AGENT_CHAT = "/conversation/agent-chat";
  static OPEN_CHATS_SESSION_IDS = "/conversation/get-open-conversations"
  static CHANGE_STATUS = "/conversation/conversation-status";
  static BULK_CHANGE_STATUS = "/conversation/bulk-conversation-status";
}

export class APPSTORE_ENDPOINTS {
  static AVAILABLE_APPS = "/workspace/app-store/available-apps";
  static APP_DETAILS = "/workspace/app-store/app-detail";
  static WOOCOMMERCE_CONNECT = "/workspace/app-store/woocommerce/auth-connect";
}