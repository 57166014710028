// src/AppStore.tsx
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Grid, Tab, Avatar } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../assets/images/Searchicon.svg";
import { ReactComponent as AppStoreIcon } from "../../assets/images/marketplace/app-store-main.svg";
import { useNavigate } from "react-router-dom";
import { theme } from "../../Customization/Theme";
import NavigationBar from "./NavigationBar";
import { AppDetailTabs } from "./AppDetail/AppDetailStyles";
import _ from "lodash";
import {
  selectAllApps,
  selectInstalledApps,
  selectAppStatus,
  selectAppError,
} from "../../Store/Slices/AppStore/appStore.selector"; // Adjust the path as necessary
import { useAppDispatch, useAppSelector } from "../../Store";
import { AppActions } from "../../Store/Slices/AppStore/appStore.action";
import { SxProps, Theme } from "@mui/system";

const AppStore: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [query, setQuery] = useState("");

  const appStoreData = useAppSelector(
    selectedTab === 0 ? selectAllApps : selectInstalledApps
  );
  const appStatus = useAppSelector(selectAppStatus);
  const appError = useAppSelector(selectAppError);

  useEffect(() => {
    if (selectedTab === 0 && appStatus === "idle") {
      dispatch(AppActions.fetchAvailableApps());
    } else if (selectedTab === 1) {
      dispatch(AppActions.fetchInstalledApps());
    }
  }, [selectedTab]);

  const handleSelectSearch = (e: any) => {
    
  };

  const redirectToAppDetails = (id: string) => {
    navigate(`app-detail/${id}`);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const debouncedSearch = useCallback(
    _.debounce((query) => {
      handleSelectSearch(query);
    }, 500),
    [] // empty dependency array to ensure it's only created once
  );

  // Handle input change
  const handleChangeSearch = (e: any) => {
    const value = e.target.value;
    setQuery(value);
    debouncedSearch(value);
  };
  // if (appStatus === "loading") {
  //   return <div>Loading...</div>;
  // }

  // if (appStatus === "failed") {
  //   return <div>Error: {appError}</div>;
  // }

  return (
    <AppStoreWrapper>
      <NavigationBar list={["App Store"]} />

      <Box className="body">
        <Box className="main__header">
          <Box className="header__left">
            <Box className="header__text">
              Choose professional solutions to power your business
            </Box>
            <SearchInput>
              <input
                type="text"
                onChange={handleChangeSearch}
                name="query"
                id="query"
                placeholder="Search"
              />
              <SearchIcon />
            </SearchInput>
          </Box>
          <Box className="header__right">
            <Box className="header__img">
              <AppStoreIcon />
            </Box>
          </Box>
        </Box>
        <Box className="app__info">
          <Box className="heading">
            <Box className="heading__text">Apps</Box>
            <Box className="heading__desc">
              Customize and enhance your plugins limits
            </Box>
          </Box>
          <AppDetailTabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="app tabs"
          >
            <Tab label="Explore" />
            <Tab label="Installed" />
          </AppDetailTabs>
          <Box sx={{ flexGrow: 1, height: "100%" }} className="all__apps">
            <Grid
              container
              spacing={{ xs: 2, xl: 4 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            >
              {appStoreData?.map((i) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i.id}>
                  <AppsCard onClick={() => redirectToAppDetails(i.id)}>
                    <Box className="top">
                      <Box>
                        <Avatar
                          slotProps={{
                            img: {
                              style: {
                                objectFit: "scale-down",
                                padding: "5px",
                              },
                            },
                          }}
                          key="1"
                          alt="Image 1"
                          src={i.icon}
                        />
                      </Box>
                      <Box className={i.is_free ? "isFree" : "isPaid"}>
                        {i.is_free ? "Free" : "Paid"}
                      </Box>
                    </Box>
                    <Box className="middle">{i.title}</Box>
                    <Box className="bottom">{i.description}</Box>
                  </AppsCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </AppStoreWrapper>
  );
};

export default AppStore;

const AppStoreWrapper = styled.div`
  && {
    height: 100%;
    .body {
      padding-bottom: 1rem;
      overflow-y: auto;
      // height: calc(100% - 57px);
      .app__info {
        border-radius: 4px;
        margin: 0 1rem;
        background-color: ${theme.palette.default.white};
        padding: 24px;
        padding-right: 8px;
        padding-left: 12px;
        .heading {
          margin-bottom: 24px;
          .heading__text {
            font-size: 18px;
            font-weight: 500;
            color: ${theme.palette.default.black};
          }
          .heading__desc {
            font-size: 14px;
            font-weight: 400;
            color: ${theme.palette.default.darkGrey};
          }
        }
        .all__apps {
          padding-right: 16px;
          padding-top: 14px;
          padding-left: 12px;
        }
      }
    }
    .main__header {
      padding: 0 24px;
      padding-top: 12px;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header__left {
        min-width: calc(100vw - 789px);
        display: flex;
        flex-direction: column;
        gap: 48px;
        .header__text {
          color: ${theme.palette.default.black};
          font-size: 40px;
          font-weight: 500;
          max-width: 626px;
          line-height: 48px;
        }
      }
      .header__right {
        display: flex;
        align-items: center;
        justify-content: center;
        .header__img {
          width: 100%;
          img {
            width: 100%;
            min-height: 269px;
          }
        }
      }
    }
  }
`;

const SearchInput = styled.div`
  && {
    max-width: 881px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      width: 100%;
      height: 48px;
      border-radius: 30px;
      padding: 0 16px;
      font-size: 16px;
      border: 1px solid #cbcdd3;
    }
    svg {
      position: absolute;
      right: 16px;
      cursor: pointer;
    }
  }
`;

const AppsCard = styled.div`
  && {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 4px;
    min-height: 180px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .isFree {
        font-size: 12px;
        background-color: ${theme.palette.default.teaGreen};
        border: 1px solid ${theme.palette.default.success};
        color: ${theme.palette.default.success};
        border-radius: 30px;
        min-width: 48px;
        min-height: 17px;
        text-align: center;
      }
      .isPaid {
        font-size: 12px;
        background-color: ${theme.palette.default.bisque};
        border: 1px solid ${theme.palette.default.error};
        color: ${theme.palette.default.error};
        border-radius: 30px;
        min-width: 48px;
        min-height: 17px;
        text-align: center;
      }
    }
    .middle {
      font-size: 14px;
      font-weight: 500;
      color: ${theme.palette.default.black};
      margin-bottom: 10px;
    }
    .bottom {
      font-size: 12px;
      font-weight: 400;
      color: ${theme.palette.default.darkGrey};
    }
    &:hover {
      cursor: pointer !important;
      box-shadow: 0px 0px 20px 0px rgba(91, 115, 232, 0.3);
      .isFree,
      .top,
      .isPaid,
      .middle,
      .bottom {
        cursor: pointer !important;
      }
    }
  }
`;
