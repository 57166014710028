import { useEffect, useState } from "react";
import { TimeoutHeader, TimeoutWrapper } from "./Opt-inManagemntStyle";
import './OptOutSettings.css'; // Make sure you create this CSS file
import { ReactComponent as DeleteIcon } from "../../../../assets/images/delete.svg";
import AppButton from "../../../../AppComponents/AppButton";
import ConfigModal from "./SetConfigModel";
import { WhatsappSettingsActions } from "../../../../Store/Slices/Settings/WhatsappSettings/whatsappSettings.actions";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";
import { FlowBuilderService } from "../../../../Services/Apis/FlowBuilder";
import { AxiosResponse } from "axios";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";

const OptInManagement = () => {
    const [responseConfiguredOptIn, setResponseConfiguredOptIn] = useState(true);
    const [optInKeywords, setOptInKeywords] = useState(["Allow"]);
    const dispatch = useAppDispatch();
    const { userConfig }: any = useAppSelector(useAuthStore);
    const [responseConfiguredOptOut, setResponseConfiguredOptOut] = useState(true);
    const [optInConfig, setOptInConfig] = useState<any>({});
    const [optOutConfig, setOptOutConfig] = useState<any>({});
    const [selectConfig, setSelectConfig] = useState({});
    const [selectAction, setSelectAction] = useState("");
    const [optOutKeywords, setOptOutKeywords] = useState(["Stop"]);
    const { showAlert } = useNotification();
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    async function getWhstappUserConsent(botId: any) {
        FlowBuilderService.getWhatsappUserConsent(
            {},
            { appid: JSON.stringify({ botid: botId }) }
        ).then((response: AxiosResponse<any>) => {
            setOptInKeywords(response?.data?.optin?.config?.keywords || [])
            setOptOutKeywords(response?.data?.optout?.config?.keywords || [])
            setOptInConfig(response?.data?.optin?.config)
            setOptOutConfig(response?.data?.optout?.config)
            setResponseConfiguredOptIn(response?.data?.optin?.status === "inactive" ? false : true)
            setResponseConfiguredOptOut(response?.data?.optout?.status === "inactive" ? false : true)
        });
    }
    useEffect(() => {
        if (userConfig?.payload?.whatasapp_config?.botid) {
            getWhstappUserConsent(userConfig?.payload?.whatasapp_config?.botid)
        }
    }, [userConfig?.payload?.whatasapp_config?.botid])
    const handleAddKeyword = (type: any) => {
        if (type === 'optIn' && optInKeywords.length < 5) {
            setOptInKeywords([...optInKeywords, ""]);
        } else if (type === 'optOut' && optOutKeywords.length < 5) {
            setOptOutKeywords([...optOutKeywords, ""]);
        }
    };

    const handleRemoveKeyword = (index: any, type: any) => {
        if (type === 'optIn') {
            const newKeywords = optInKeywords.filter((_, i) => i !== index);
            setOptInKeywords(newKeywords);
        } else if (type === 'optOut') {
            const newKeywords = optOutKeywords.filter((_, i) => i !== index);
            setOptOutKeywords(newKeywords);
        }
    };

    const handleChangeKeyword = (e: any, index: any, type: any) => {
        const value = e.target.value;
        if (type === 'optIn') {
            const newKeywords = [...optInKeywords];
            newKeywords[index] = value;
            setOptInKeywords(newKeywords);
        } else if (type === 'optOut') {
            const newKeywords = [...optOutKeywords];
            newKeywords[index] = value;
            setOptOutKeywords(newKeywords);
        }
    };

    const onSave = (action: any,response?:any,configureAction ? :any     ) => {
        let data;
        let botId = userConfig?.payload?.whatasapp_config?.botid

        if (action === "optOut") {
            data = {
                "optout": {
                    "type": "opt-out",
                    "channel": "whatsapp",
                    "botid": userConfig?.payload?.whatasapp_config?.botid,
                    "status": responseConfiguredOptOut ? "active" : "inactive",
                    "created_by": "",
                    "config": {
                        "keywords": optOutKeywords,
                        "response": response ? response : optOutConfig?.response,
                        "type": configureAction ? configureAction : optOutConfig?.type
                    }
                }
            }
        } else {
            data = {
                "optin": {
                    "type": "opt-in",
                    "channel": "whatsapp",
                    "botid": userConfig?.payload?.whatasapp_config?.botid,
                    "status": responseConfiguredOptIn ? "active" : "inactive",
                    "created_by": "",
                    "config": {
                        "keywords": optInKeywords,
                        "response": response ? response : optInConfig?.response,
                        "type": "static"
                    }
                }
            }
        }
        console.log("responseConfiguredOptIn", data);

        FlowBuilderService.updateWhatsappUserConsent(
            data,
            { appid: JSON.stringify({ botid: botId }) }
        ).then((response: AxiosResponse<any>) => {
            getWhstappUserConsent(botId)
            showAlert("Updateds Successfully", "success");
        });
    }
    return (
        <TimeoutWrapper>
            <TimeoutHeader>
                <div className="response-header__left">
                    <h4>Opt-In Management</h4>
                </div>
                <div className="response-header__right">
                    <div className="response-search"></div>
                    <div className="response-button"></div>
                </div>
            </TimeoutHeader>

            {/* Opt-Out Section */}
            <div className="opt-out-container">
                <div className="opt-out-keywords">
                    <h3>Opt-out Keywords</h3>
                    <p>The user will have to type exactly one of these messages on which they should be automatically opted-out</p>
                    {optOutKeywords?.map((keyword, index) => (
                        <div key={index} className="keyword-entry" style={{ display: "flex" }}>
                            <input
                                type="text"
                                placeholder="Enter Keyword"
                                value={keyword}
                                className="input-field"
                                onChange={(e) => handleChangeKeyword(e, index, 'optOut')}
                            />
                            <AppButton
                                onClick={() => handleRemoveKeyword(index, 'optOut')}
                                style={{
                                    margin: "14px 9px",
                                    padding: "1px"
                                }}
                            >
                                <DeleteIcon />
                            </AppButton>
                        </div>
                    ))}
                    {optOutKeywords.length < 5 && (
                        <a href="#" className="add-more" onClick={() => handleAddKeyword('optOut')}>+ Add more</a>
                    )}
                    <br />
                    <button className="save-settings" onClick={() => onSave("optOut")}>Save Settings</button>
                </div>

                <div className="opt-out-response">
                    <div className="toggle-container">
                        <h3>Opt-out Response <br /> <p>Setup a response message for opt-out user keywords</p></h3>
                        <label className="switch">
                            <input type="checkbox" checked={responseConfiguredOptOut} onChange={() => setResponseConfiguredOptOut(!responseConfiguredOptOut)} />
                            <span className="slider"></span>
                        </label>
                        <button className="configure-button" onClick={() => { setShowModal(true); setSelectConfig(optOutConfig);setSelectAction("optOut") }}>Configure</button>
                    </div>
                    {responseConfiguredOptOut && (
                        <div className="whatsapp-message">
                            <span className="whatsapp-icon">💬</span>
                            <span>You have been opted-out of your future communications</span>
                        </div>
                    )}
                </div>
            </div>

            {/* Opt-In Section */}
            <div className="opt-out-container">
                <div className="opt-out-keywords">
                    <h3>Opt-in Keywords</h3>
                    <p>The user will have to type exactly one of these messages on which they should be automatically opted-in</p>
                    {optInKeywords?.map((keyword, index) => (
                        <div key={index} className="keyword-entry" style={{ display: "flex" }}>
                            <input
                                type="text"
                                placeholder="Enter Keyword"
                                value={keyword}
                                className="input-field"
                                onChange={(e) => handleChangeKeyword(e, index, 'optIn')}
                            />
                            <AppButton
                                onClick={() => handleRemoveKeyword(index, 'optIn')}
                                style={{
                                    margin: "14px 9px",
                                    padding: "1px"
                                }}
                            >
                                <DeleteIcon />
                            </AppButton>                        </div>
                    ))}
                    {optInKeywords.length < 5 && (
                        <a href="#" className="add-more" onClick={() => handleAddKeyword('optIn')}>+ Add more</a>
                    )}
                    <br />
                    <button className="save-settings" onClick={() => onSave("optIn")}>Save Settings</button>
                </div>

                <div className="opt-out-response">
                    <div className="toggle-container">
                        <h3>Opt-in Response <br /> <p>Setup a response message for opt-in user keywords</p></h3>
                        <label className="switch">
                            <input type="checkbox" checked={responseConfiguredOptIn} onChange={() => setResponseConfiguredOptIn(!responseConfiguredOptIn)} />
                            <span className="slider"></span>
                        </label>
                        <button className="configure-button" onClick={() => { setShowModal(true); setSelectConfig(optInConfig); setSelectAction("optIn") }}>Configure</button>
                    </div>
                    {responseConfiguredOptIn && (
                        <div className="whatsapp-message">
                            <span className="whatsapp-icon">💬</span>
                            <span>Thanks, You have been opted-in of your future communications</span>
                        </div>
                    )}
                </div>
            </div>
             { selectConfig ? <ConfigModal onSave={onSave}selectAction={selectAction}  selectConfig={selectConfig} show={showModal} onClose={() => {setShowModal(false);setSelectConfig({})}} /> :null}
        </TimeoutWrapper>
    );
};

export default OptInManagement;
