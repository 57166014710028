import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userStore } from "./user_store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { flowBuilderReducer } from "./../Modules/FlowBuilder/Slices";
import sliceLayout from "./Slices/ActiveLayoutState";
// socket
import AllMessagesReducer from "./Slices/socket/AllMessagesSlice";
import SocketSliceReducer from "./Slices/socket/SocketSlice";
import CurrentUserSliceReducer from "./Slices/CurrentUser/CurrentUserSlice";
import DeviceSizeReducer from "./Slices/socket/DeviceSizeSlice";
import BotInfoReducer from "./Slices/socket/BotInfoSlice";
import botsReducer from "./Slices/AllBotsSlice";
import authReducer from "./Slices/AuthSlice/UserSlice";
import sliceLanguages from "./Slices/LanguageSlice";
import formReducer from "./Slices/formSlice";
import facebookSettingsSliceReducer from "./Slices/Settings/facebookSettings/facebookSettings.slice";
import instagramSettingsSliceReducer from "./Slices/Settings/instagramSettings/instagramSettings.slice";

import ContactsListSliceReducer from "./Slices/Contacts/contacts.slice";
import whatsappSliceReducer from "./Slices/Whatsapp/whatsapp.slice";
import emailSettingsSliceReducer from "./Slices/Settings/EmailSettings/emailSettings.slice";
import contactPropertiesSliceReducer from "./Slices/Settings/ContactProperties/contactProperties.slice";
import whatsappSettingsSliceReducer from "./Slices/Settings/WhatsappSettings/whatsappSettings.slice";
import livechatSliceReducer from "./Slices/Settings/LiveChat/CannedResponse/cannedres.slice";
import chatLabelReducer from "./Slices/Settings/LiveChat/ChatLabel/chatlabel.slice";
import userReducer from "./Slices/Settings/LiveChat/User/user.slice";
import roleReducer from "./Slices/Settings/LiveChat/RolesPermission/role.slice";
import settingsWorkspaceReducer from "./Slices/Settings/LiveChat/Workspace/workplace.slice";
import SMSSliceReducer from "./Slices/SMS/sms.slice";
import LiveChatSliceReducer from "./Slices/socket/liveChat/chat.slice";
import LiveChatWhatsappTemplateReducer from "./Slices/socket/liveChatWhatsappTemplateSelect/LiveChatWhatsappTemplateSelect.slice";
import DashboardSliceReducer from "./Slices/DashboardRedux/dashboard.slice";
// import LiveChatActiveFlitersReducer from "./Slices/socket/liveChat/LiveChatActiveFlitersSlice";
import EmailSliceReducer from "./Slices/email/email.slice";
import MarketingListSliceReducer from "./Slices/MarketingList/marketingList.slice";
import ContactListSliceReducer from "./Slices/ContactList/ContactList.slice";
import QueryBuilderSliceReducer from "./Slices/QueryBuilder/QueryBuilder.slice";
import FaqSourceSliceReducer from "./Slices/Faq/Source/source.slice";
import FaqUserQuerySliceReducer from "./Slices/Faq/UserQueries/userquery.slice";
import AutoAssignmentRulesSettingsSliceReducer from "./Slices/Settings/Automation/AutoAssignmentRules/autoAssignmentRulesSettings.slice";
import SegmentSliceReducer from "./Slices/Segment/Segmnet.slice";
import dashboardReducer from "./Slices/DashboardAnalytics/dashboardanalytics.slice";
import CreditDetailSliceReducer from "./Slices/CreditDetails/CreditDetails.slice";
import AutomationBuilderSliceReducer from "./Slices/AutomationBuilder/automationBuilder.slice";
import subscriptionsSlice from "./Slices/Subscriptions/subscriptions.slice";
import lifecycleStageSlice from "./Slices/Settings/LifecycleStage/lifecycleStage.slice";
import BroadcastSliceReducer from "./Slices/BroadcastDashboard/BroadcastDash.slice";
import BotConfigReducer from "./Slices/botConfig/BotConfigSlice";
import formbuilderSlice from "./Slices/FormBuilder/formbuilder.slice";
import formApplicationsSlice from "./Slices/ApplicationsForm/applicationForm.slice";
import WhatsappFlowBuilderSlice from "../Whatsapp/Store/FlowBuilder.slice";
import OnboardingSliceReducer from "./Slices/Onboarding/onboarding.slice";
import { logout } from "./logout"; // Import the logout action
import apikeySlice from "./Slices/APIKey/apikey.slice";
import webhookSlice from "./Slices/Webhook/webhook.slice";

// ******************* APP STORE ****************************
import appStoreSlice from "./Slices/AppStore/appStore.slice";
// **********************************************************

const appReducer = combineReducers({
  // reducer: {
    languages: sliceLanguages,
    authData: authReducer,
    userData: userStore,
    currrentBotConfig: BotConfigReducer,
    activeLayout: sliceLayout,
    //socket
    currrentAllMessagesStore: AllMessagesReducer,
    currrentSocketStatus: SocketSliceReducer,
    currrentDeviceSize: DeviceSizeReducer,
    currrentBotInfo: BotInfoReducer,
    bots: botsReducer,
    flowBuilder: flowBuilderReducer,
    form: formReducer,
    whatsapp: whatsappSliceReducer,
    livechat: livechatSliceReducer,
    settingsWorkspace: settingsWorkspaceReducer,
    chatlabel: chatLabelReducer,
    emailSettings: emailSettingsSliceReducer,
    contactProperties: contactPropertiesSliceReducer,
    whatsappSettings: whatsappSettingsSliceReducer,
    facebookSettings: facebookSettingsSliceReducer,
    instagramSettings: instagramSettingsSliceReducer,
    marketing: MarketingListSliceReducer,
    SMS: SMSSliceReducer,
    Contacts: ContactsListSliceReducer,
    chat: LiveChatSliceReducer,
    currentLiveChatWhatsappTemplate: LiveChatWhatsappTemplateReducer,
    email: EmailSliceReducer,
    user: userReducer,
    userRoles: roleReducer,
    subscription: subscriptionsSlice,
    automationBuilder: AutomationBuilderSliceReducer,
    currentUser: CurrentUserSliceReducer,
    dashboard: DashboardSliceReducer,
    faqSource: FaqSourceSliceReducer,
    faqUserQueries: FaqUserQuerySliceReducer,
    segment: SegmentSliceReducer,
    dashboardAnalytics: dashboardReducer,
    contact: ContactListSliceReducer,
    creditDetails: CreditDetailSliceReducer,
    queryBuilder: QueryBuilderSliceReducer,
    autoAssignmentRulesSettings: AutoAssignmentRulesSettingsSliceReducer,
    lifecycleStage: lifecycleStageSlice,
    broadcast: BroadcastSliceReducer,
    formBuilder: formbuilderSlice,
    applicationForm: formApplicationsSlice,
    appStore:appStoreSlice,
    whatsappFlowBuilder: WhatsappFlowBuilderSlice,
    apikey: apikeySlice,
    webhook: webhookSlice,
    onboarding: OnboardingSliceReducer,
  // },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
});

// Create a root reducer that resets the state on logout
const rootReducer = (state: any, action: any) => {
  if (action.type === logout.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export * from "./Slices";
export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector; // Export a hook that can be reused to resolve types
