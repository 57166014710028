import * as Yup from "yup";
import { checkDuplicatesArray } from "../Utils";
import ValidateCountryMobileNumber from "./CountryMobileRegex";
import {
  popupPositionOptions,
  widgetPositionOptions,
} from "./defaultSelectOptions";

export const UUID_REGEX =
  "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const IP_ADDRESS_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const URLREGEX =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

export const newURLRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

export const WEB_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const ALL_ZERO_REGEX = /^(?=.*\d)(?=.*[1-9]).{1,10}$/;

export const TIME_REGEX = "([01]?[0-9]|2[0-3]):[0-5][0-9]";

export const ONLY_CHARACTER_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð 0-9]+$/u;

export const SIMPLE_URL_REGEX = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

export const socialMediaValidationSchema = Yup.object().shape({
  facebook_profile: Yup.string()
    .nullable()
    .transform((curr: any, val: any) => (val === "" ? null : curr))
    .matches(WEB_REGEX, "Enter a valid url")
    .matches(WEB_REGEX, "Enter a valid website"),
  twitter_profile: Yup.string()
    .nullable()
    .transform((curr: any, val: any) => (val === "" ? null : curr))
    .matches(WEB_REGEX, "Enter a valid url")
    .matches(WEB_REGEX, "Enter a valid website"),
  linkedin_profile: Yup.string()
    .nullable()
    .transform((curr: any, val: any) => (val === "" ? null : curr))
    .matches(WEB_REGEX, "Enter a valid url")
    .matches(WEB_REGEX, "Enter a valid website"),
});

export const BotValidationSchema = Yup.object().shape({
  name: Yup.string()
    .notRequired()
    .when("channel", {
      is: (val: string) => val !== "telegram",
      then: Yup.string()
        .min(2)
        .max(24)
        .required("Name is a required field")
        .label("Name"),
    }),
  // channel: Yup.string()
  //   .min(2)
  //   .max(24)
  //   .required("Channel is a required field")
  //   .label("Channel"),
  bot_token: Yup.string()
    .notRequired()
    .label("Bot Token")
    .when("channel", {
      is: (val: string) => val === "telegram",
      then: Yup.string()
        .matches(/^\d+:[A-Za-z0-9_\-]{35}$/, "please enter a valid token")
        .required("Channel is a required field"),
    }),
  fb_page_id: Yup.string()
    .notRequired()
    .label("Facebook Page")
    .when("channel", {
      is: (val: string) => val === "facebook",
      then: Yup.string().required("Please select a facebook page"),
    }),
  // bot_type: Yup.lazy((value: any) => {
  //   if (value == 0 || value == "0") {
  //     return Yup.number()
  //       .required()
  //       .min(1, "Type is a required field")
  //       .label("Type");
  //   } else {
  //     return Yup.string()
  //       .required()
  //       .min(1, "Type is a required field")
  //       .label("Type");
  //   }
  // }),
  // primary_language: Yup.lazy((value: any) => {
  //   if (value == 0 || value == "0") {
  //     return Yup.number()
  //       .required("Select a option is required")
  //       .min(1, "Select a desired option")
  //       .label("Primary Language");
  //   } else {
  //     return Yup.string()
  //       .required("Select a option is required")
  //       .min(1, "Select a desired option")
  //       .label("Primary Language");
  //   }
  // }),
  // timezone: Yup.mixed()
  //   .required()
  //   // .min(2, "Select a desired option")
  //   .label("Timezone"),
  // bot_category: Yup.lazy((value: any) => {
  //   if (value == 0 || value == "0") {
  //     return Yup.number()
  //       .required()
  //       .min(1, "Category is a required field")
  //       .label("Category");
  //   } else {
  //     return Yup.string()
  //       .required()
  //       .min(1, "Category is a required field")
  //       .label("Category");
  //   }
  // }),
});
export const WorkspaceValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2)
    .max(24)
    .required("Name is a required field")
    .label("Name"),
  primary_language: Yup.lazy((value: any) => {
    if (value == 0 || value == "0") {
      return Yup.number()
        .required("Select a option is required")
        .min(1, "Select a desired option")
        .label("Primary Language");
    } else {
      return Yup.string()
        .required("Select a option is required")
        .min(1, "Select a desired option")
        .label("Primary Language");
    }
  }),
  timezone: Yup.mixed()
    .required()
    // .min(2, "Select a desired option")
    .label("Timezone"),
});

export const BotPersistantMenu = Yup.object().shape({
  dynamicInputs: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().min(2).max(30).required("Text is required"),
        action: Yup.object().nullable().required("Action is required"),
        flow: Yup.object()
          .nullable()
          .optional()
          .when(["action"], (actionValue: any, schema: any) => {
            return actionValue?.type === "flow"
              ? schema.nullable().required("flow is required")
              : schema.optional();
          }),
        node: Yup.object()
          .nullable()
          .optional()
          .when(["flow"], (flowValue: any, schema: any) => {
            return flowValue?.qid?.length > 0
              ? schema.nullable().required("flow is required")
              : schema.optional();
          }),
        link: Yup.string()
          .optional()
          .when(["action"], {
            is: (action: any) => {
              return action?.type === "link";
            },
            then: Yup.string()
              .matches(newURLRegex, "Invalid url")
              .required("URL is required"),
          }),
      }),
    )
    .test(
      "at-least-one",
      "At least one dynamic input is required",
      function (value: any) {
        return value && value.length > 0;
      },
    )
    .required("At least one dynamic input is required"),
});
export const BotWelcomeMessage = Yup.object().shape({
  welcome_message: Yup.string()
    .min(2)
    .max(160)
    .required("Bot Message is required")
    .label("Bot Message"),
});
export const BotCustomizeSchema = Yup.object().shape({
  bot_name: Yup.string()
    .min(2)
    .max(24)
    .required("Bot Name is required")
    .label("Bot Name"),
  primary: Yup.lazy((value: any) => {
    if (value == "#ffffff") {
      return Yup.string()
        .required("Primary Color is required")
        .label("Accent Color")
        .min(7, "Currently white is not supported")
        .max(9, "Currently white is not supported");
    } else {
      return Yup.string()
        .required("Primary Color is required")
        .label("Accent Color")
        .min(4, "Incorrect Color code")
        .max(7, "Incorrect Color code");
    }
  }),
  font_family: Yup.lazy((value: any) => {
    if (value == 0 || value == "0") {
      return Yup.number()
        .required("Font family is required")
        .min(1, "Select a desired option")
        .label("Font Family");
    } else {
      return Yup.string()
        .required("Font family is required")
        .min(1, "Select a desired option")
        .label("Font Family");
    }
  }),
  font_size: Yup.lazy((value: any) => {
    if (value == 0 || value == "0") {
      return Yup.number()
        .required("Font Size is required")
        .min(1, "Select a desired option")
        .label("Font Size");
    } else {
      return Yup.string()
        .required("Font Size is required")
        .min(1, "Select a desired option")
        .label("Font Size");
    }
  }),
  widget_position: Yup.string()
    .oneOf(widgetPositionOptions, "only select from these options")
    .required("Select a option for position")
    .label("Widget Position"),
  // company_logo: Yup.mixed()
  //   .required("Company logo is required")
  //   .label("Company Logo"),
  // avatar: Yup.mixed().required("Bot Avatar is required").label("Bot Avatar"),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
  selected_business: Yup.string(),
  password: Yup.string().min(6).max(24).required().label("Password"),
});

export const BusinessSignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3)
    .max(24)
    .matches(ONLY_CHARACTER_REGEX, "Enter a valid name")
    .required()
    .label("First Name"),
  last_name: Yup.string()
    .min(3)
    .max(24)
    .matches(ONLY_CHARACTER_REGEX, "Enter a valid name")
    .required()
    .label("Last Name"),
  email: Yup.string().email().required().label("Email"),
  phone: Yup.string()
    .min(1, "Invalid Mobile format")
    .max(19)
    .required()
    .label("Mobile")
    .test("strongPhoneTest", null, (value: any, ctx: any) => {
      let mobileCountryFormat: any = ctx?.parent?.mobileCountryFormat;

      let format: any = mobileCountryFormat?.format
        ?.replaceAll(".", "0")
        ?.replaceAll(" ", "")
        ?.replaceAll("(", "")
        ?.replaceAll(")", "")
        ?.replaceAll("-", "")
        ?.replaceAll("+", "");
      let dialCodeLength: any = mobileCountryFormat?.dialCode?.length;
      let formatWithoutDialCode: any = format?.substring(dialCodeLength);
      let valueWithoutDialCode: any = value?.substring(dialCodeLength);

      if (formatWithoutDialCode?.length == valueWithoutDialCode?.length) {
        let count = 0;
        for (var i = 0; i < valueWithoutDialCode?.length; i++) {
          if (
            valueWithoutDialCode.charAt(i) == 0 ||
            valueWithoutDialCode.charAt(i) == "0"
          ) {
            count = count + 1;
          }
        }
        if (formatWithoutDialCode?.length == count) {
          return new Yup.ValidationError(
            `Please enter valid Mobile number.`,
            null,
            "phone",
          );
        } else {
          return true;
        }
      } else {
        return new Yup.ValidationError(
          `Please enter valid Mobile format for ${mobileCountryFormat.name} ${formatWithoutDialCode?.length} digits required`,
          null,
          "phone",
        );
      }
    }),
  business_name: Yup.string()
    .min(1)
    .max(60)
    .matches(ONLY_CHARACTER_REGEX, "Enter a valid name")
    .required()
    .label("Business Name"),
  business_domain: Yup.string()
    .matches(URLREGEX, "Enter a valid website")
    .required()
    .label("Domain url"),
  password: Yup.string()
    .min(8)
    .max(32)
    .required()
    .test("strongPasswordTest", null, (value: any) => {
      switch (Boolean(value)) {
        case !/^(?=.*[a-z])/.test(value):
          return new Yup.ValidationError(
            `Password must include lowercase letter[a-z]`,
            null,
            "password",
          );
        case !/^(?=.*[A-Z])/.test(value):
          return new Yup.ValidationError(
            `Password must include uppercase letter[A-Z]`,
            null,
            "password",
          );
        case !/^(?=.*[0-9])/.test(value):
          return new Yup.ValidationError(
            `Password must include digit [0-9]`,
            null,
            "password",
          );
        case !/^(?=.*[!@#\$%\^&\*])/.test(value):
          return new Yup.ValidationError(
            `Password must include special [!@#$%^&*]`,
            null,
            "password",
          );
        default:
          return true;
      }
    })
    .label("Password"),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .label("Confirm password"),
  terms: Yup.bool().oneOf([true], "T&C  is a required field"),
});

export const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(32, "Password can be maximum 32 characters")
    .required("Password is required")
    .test("strongPasswordTest", null, (value: any) => {
      switch (Boolean(value)) {
        case !/^(?=.*[a-z])/.test(value):
          return new Yup.ValidationError(
            `Password must include lowercase letter[a-z]`,
            null,
            "password",
          );
        case !/^(?=.*[A-Z])/.test(value):
          return new Yup.ValidationError(
            `Password must include uppercase letter[A-Z]`,
            null,
            "password",
          );
        case !/^(?=.*[0-9])/.test(value):
          return new Yup.ValidationError(
            `Password must include digit [0-9]`,
            null,
            "password",
          );
        case !/^(?=.*[!@#\$%\^&\*])/.test(value):
          return new Yup.ValidationError(
            `Password must include special [!@#$%^&*]`,
            null,
            "password",
          );
        default:
          return true;
      }
    })
    .label("Password"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid Email")
    .required("Email is required")
    .label("Email"),
});

export const EmailNotificationSchema = Yup.object().shape({
  enabled: Yup.boolean(),

  emails: Yup.array()
    .notRequired()
    .label("Emails")
    .when("enabled", {
      is: true,
      then: Yup.array()
        .min(1, "Atleast 1 email is required")
        .required("Email is required ")
        .max(5, "Only 5 emails are allowed")
        .label("Emails"),
    }),
  email_view: Yup.string()
    .label("Email View")
    .when("enabled", {
      is: true,
      then: Yup.string()
        .required("Select a option for email style")
        .label("Email View"),
    }),
});

export const SmsNotificationSchema = Yup.object().shape({
  mobile: Yup.string()
    .min(11)
    .max(19)
    .required("Mobile is required")
    .label("Mobile"),
});
export const SegmentSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .max(40)
    .required("Segment Name is required")
    .label("name"),
});
export const PopoutMessageSchema = Yup.object().shape({
  tooltip_text: Yup.string()
    .min(4)
    .max(100)
    .required("Popout Mesaage is required")
    .label("Popout Message"),

  enabled: Yup.boolean(),
  position: Yup.string()
    .oneOf(popupPositionOptions, "only select from these options")
    .required("Select a option for position")
    .label("PopUp Position"),
});

export const NotificationsPreferencesSchema = Yup.object().shape({
  enabled: Yup.boolean(),
  volume_level: Yup.number(),
});
export const UnbrandingWidgetSchema = Yup.object().shape({
  footer_logo: Yup.mixed().label("Footer logo"),
  footer_url: Yup.string()
    .matches(URLREGEX, "Enter a valid website")
    .label("Footer url"),
});

export const EnabledDevicesSchema = Yup.object().shape({
  desktop: Yup.boolean(),
  tablet: Yup.boolean(),
  mobile: Yup.boolean(),
});

export const BlackListUrlSchema = Yup.object().shape({
  blacklisted_urls: Yup.array()
    .of(
      Yup.string()
        .required("Url is required")
        .matches(SIMPLE_URL_REGEX, "Enter a valid Url"),
    )
    .notRequired(),
});
export const CannedResponseSchema = Yup.object().shape({
  short_code: Yup.string()
    .min(3, "Short Code must be at least 3 characters")
    .max(24, "Short Code can be maximum 24 characters")
    .required("Short Code is required")
    .label("Short Code"),
  response: Yup.string()
    .required("Response is required")
    .min(3, "Response must be at least 3 characters")
    .max(24, "Response can be maximum 24 characters")
    .label("Response"),
});
export const BanUserSchema = Yup.object().shape({
  user_ip: Yup.string()
    .matches(IP_ADDRESS_REGEX, "invalid IP address")
    .required("User IP is required")
    .label("User IP"),
  reason: Yup.string()
    .required("Reason is required")
    .min(3, "Reason must be at least 3 characters")
    .max(24, "Reason can be maximum 24 characters")
    .label("Reason"),
});

export const AddFaqsSchema = Yup.object().shape({
  question: Yup.string()
    .max(100, "Question can be maximum 100 characters")
    .required("Question is required")
    .label("Question"),
});

export const FAQDetailsSchema = Yup.object().shape({
  question: Yup.string()
    .required("Question is required")
    .min(1, "Question must be at least 1 characters")
    .label("Question"),
  response: Yup.array().notRequired().nullable(),

  phrases: Yup.array()
    .required("Atleast 5 variations Reqired")
    .min(5, "Atleast 5 variations Reqired")
    .of(
      Yup.string()
        .required("Phrase is required")
        .min(1, "Phrase must be at least 1 character"),
    )
    .test("Unique Value Test", null, (value: any, ctx: any) => {
      let question: any = ctx?.parent?.question;
      let result: any = checkDuplicatesArray(value);
      if (result) {
        return ctx?.createError({
          message: "Variation has duplicate values",
        });
      } else {
        if (value?.includes(question)) {
          return ctx?.createError({
            message: "Question and variation can't be same.",
          });
        } else {
          return true;
        }
      }
    }),

  journey: Yup.mixed().nullable(),
  text: Yup.string(),
  image: Yup.mixed().notRequired(),
  document: Yup.mixed().notRequired(),
  audio: Yup.mixed().notRequired(),
  video: Yup.mixed().notRequired(),
  link: Yup.string()
    .matches(URLREGEX, "Enter a valid website")
    .label("Link")
    .notRequired(),
});

export const CreateAgentSchema = Yup.object().shape({
  avatar: Yup.mixed().notRequired().label("Avatar"),
  first_name: Yup.string().max(50).min(2).required().label("First name"),
  last_name: Yup.string().max(50).min(2).required().label("Last name"),
  email: Yup.string().email().required().label("Email"),
  phone: Yup.string()
    .min(2)
    .max(19)
    .required()
    .test("strongPhoneTest", null, (value: any, ctx: any) => {
      let mobileCountryFormat: any = ctx?.parent?.mobileCountryFormat;

      let format: any = mobileCountryFormat?.format
        ?.replaceAll(".", "0")
        ?.replaceAll(" ", "")
        ?.replaceAll("(", "")
        ?.replaceAll(")", "")
        ?.replaceAll("-", "")
        ?.replaceAll("+", "");

      let dialCodeLength: any = mobileCountryFormat?.dialCode?.length;
      let formatWithoutDialCode: any = format?.substring(dialCodeLength);
      let valueWithoutDialCode: any = value?.substring(dialCodeLength);

      if (formatWithoutDialCode?.length == valueWithoutDialCode?.length) {
        let count = 0;
        for (var i = 0; i < valueWithoutDialCode?.length; i++) {
          if (
            valueWithoutDialCode?.charAt(i) == 0 ||
            valueWithoutDialCode?.charAt(i) == "0"
          ) {
            count = count + 1;
          }
        }
        if (formatWithoutDialCode?.length == count) {
          return new Yup.ValidationError(
            `Please enter valid number.`,
            null,
            "phone",
          );
        } else {
          return true;
        }
      } else {
        return new Yup.ValidationError(
          `Please enter valid format for ${mobileCountryFormat?.name} ${formatWithoutDialCode?.length} digits required`,
          null,
          "phone",
        );
      }
    })
    .label("Mobile"),
  concurrent_chat: Yup.number().min(1).max(1000).required().label("Number"),
  department: Yup.lazy((value: any) => {
    if (value == 0 || value == "0") {
      return Yup.number()
        .required()
        .min(1, "Select a desired option")
        .label("department");
    } else {
      return Yup.string()
        .required()
        .min(1, "Select a desired option")
        .label("Department");
    }
  }),
  role: Yup.lazy((value: any) => {
    if (value == 0 || value == "0") {
      return Yup.number()
        .required()
        .min(1, "Select a desired option")
        .label("Role");
    } else {
      return Yup.string()
        .required()
        .min(1, "Select a desired option")
        .label("Role");
    }
  }),

  password: Yup.string()
    .min(8)
    .max(32)
    .required()
    .test("strongPasswordTest", null, (value: any) => {
      switch (Boolean(value)) {
        case !/^(?=.*[a-z])/.test(value):
          return new Yup.ValidationError(
            `Password must include lowercase letter[a-z]`,
            null,
            "password",
          );
        case !/^(?=.*[A-Z])/.test(value):
          return new Yup.ValidationError(
            `Password must include uppercase letter[A-Z]`,
            null,
            "password",
          );
        case !/^(?=.*[0-9])/.test(value):
          return new Yup.ValidationError(
            `Password must include digit [0-9]`,
            null,
            "password",
          );
        case !/^(?=.*[!@#\$%\^&\*])/.test(value):
          return new Yup.ValidationError(
            `Password must include special [!@#$%^&*]`,
            null,
            "password",
          );
        default:
          return true;
      }
    })
    .label("Password"),
});
export const CreateDepartmentSchema = Yup.object().shape({
  name: Yup.string().min(2).max(24).required().label("Name"),
  description: Yup.string().min(2).max(204).required().label("Description"),
  routing: Yup.string().required().label("Routing Method"),
});

export const RoutingSchema = Yup.object()
  .shape({
    assigned: Yup.boolean().label("Assigned routing"),

    pooled: Yup.boolean().label("Pooled routing"),
  })
  .test("myCustomTest", null, (obj: any) => {
    if ((obj.assigned && !obj.pooled) || (!obj.assigned && obj.pooled)) {
      return true; // everything is fine
    }

    return new Yup.ValidationError(
      "Atleast or only one routing method needs to selected",
      null,
      "pooled",
    );
  });

export const BusinessTimingSchema = Yup.object().shape({
  always: Yup.boolean().label("Always"),
  monday: Yup.object({
    enabled: Yup.boolean().label("Enabled"),
    start: Yup.string()
      .min(3)
      .max(7)
      .matches(TIME_REGEX, "Enter a valid time HH:mm")
      .required("Start time is required")
      .label("Start time"),
    end: Yup.string()
      .min(3)
      .max(7)
      .matches(TIME_REGEX, "Enter a valid time HH:mm")
      .required("End time is required")
      .label("End time"),
  }),
});

export const AdditionalLanguageSchema = Yup.object().shape({
  language: Yup.object()
    .nullable()
    .required("language is required")
    .test("strongValueTest", null, (value: any, ctx: any) => {
      if (value == 0) {
        return new Yup.ValidationError(
          `Select a language is required`,
          null,
          "category",
        );
      }

      return true;
    })
    .label("Language"),
});

export const TeamCreateSchema = Yup.object().shape({
  team_name: Yup.string()
    .min(3, "Team name must be at least 3 characters")
    .max(24, "Team name can be maximum 24 characters")
    .required("Team name is required")
    .label("Team name"),
  members: Yup.array(),
});

export const AutoRepliesSchema = Yup.object().shape({
  outside_business_hours: Yup.object({
    message: Yup.string().min(3).max(200).required().label("Response"),
    user_info: Yup.object({
      name: Yup.boolean().label("name").notRequired(),
      email: Yup.boolean().label("Email").notRequired(),
      phone: Yup.boolean().label("Phone").notRequired(),
    }),
  }),
  default: Yup.object({
    message: Yup.string().min(3).max(200).required().label("Response"),
  }),

  conflict: Yup.object({
    message: Yup.string().min(3).max(200).required().label("Response"),
  }),
  live_agent: Yup.object({
    enabled: Yup.boolean().label("Enabled"),
    retry: Yup.number()
      // .min(1)
      .max(10)
      .label("Count")
      .test("myCustomTest", null, (value: any, ctx: any) => {
        let enabled: any = ctx?.parent?.enabled;
        if (enabled && (value === 0 || value === "0")) {
          return new Yup.ValidationError(
            `Count is a required field.`,
            null,
            "live_agent.retry",
          );
        } else {
          return true;
        }
      }),
  }),
});

export const NotificationBlockSchema = Yup.object().shape({
  image: Yup.mixed().required().label("Picture"),
  notification_title: Yup.string().min(2).max(60).required().label("Title"),

  notification_description: Yup.string()
    // return true;
    .min(2)
    .max(100)
    .required()
    .label("Description"),

  button_title: Yup.string().min(2).max(28).required().label("Button"),

  button_url: Yup.string()
    .matches(WEB_REGEX, "Enter a valid website")
    .required()
    .label("Url"),

  footer_text: Yup.string().min(2).max(20).notRequired().label("Footer"),
});

export const QuickMenuSchema = Yup.object().shape({
  image: Yup.mixed().required().label("Picture"),
  position: Yup.string().required().label("Position"),

  button_title: Yup.string()
    .max(28)
    .label("Button")
    .when(["position"], (key1: any, schema: any) => {
      return key1 === "Add Link"
        ? schema.required().min(2, "Title is a required field")
        : schema.optional();
    }),

  button_url: Yup.string()
    .max(28)
    .label("Button")
    .when(["position"], (key1: any, schema: any) => {
      return key1 === "Add Link"
        ? schema
            .required()
            .min(2, "Title is a required field")
            .matches(WEB_REGEX, "Enter a valid website")
        : schema.optional();
    }),

  main_flow: Yup.mixed("Field is required").when(
    ["position"],
    (key1: any, schema: any) => {
      return key1 === "Link To Flow"
        ? schema
            .required()
            .test(
              "notEmpty",
              "Field is required",
              (value: any) => value && Object.keys(value).length > 0,
            )
        : schema;
    },
  ),
  sub_flow: Yup.mixed("Field is required").when(
    ["position"],
    (key1: any, schema: any) => {
      return key1 === "Link To Flow"
        ? schema
            .required()
            .test(
              "notEmpty",
              "Field is required",
              (value: any) => value && Object.keys(value).length > 0,
            )
        : schema;
    },
  ),
});

export const BannerCarouselSchema = Yup.object().shape({
  View_options: Yup.string().required().label("View Options"),
  position: Yup.string().required().label("Position"),
  video: Yup.string()
    .label("Video")
    .when(["View_options"], (key1: any, schema: any) => {
      return key1 === "Video" ? schema.min(1).required() : schema.optional();
    }),
  image: Yup.mixed()
    .label("Image")
    .when(["View_options"], (key1: any, schema: any) => {
      return key1 === "Image"
        ? schema
            .required()
            .test(
              "notEmpty",
              "Field is required",
              (value: any) => value && Object.keys(value).length > 0,
            )
        : schema.optional();
    }),

  banner_url: Yup.string()
    .max(55)
    .label("Banner Link")
    .when(["position", "View_options"], (key1: any, key2: any, schema: any) => {
      return key1 === "Add Link" && key2 !== "Video"
        ? schema
            .required()
            .min(9, "Url is a required field")
            .matches(WEB_REGEX, "Enter a valid website")
        : schema.optional();
    }),

  main_flow: Yup.mixed("Field is required").when(
    ["position", "View_options"],
    (key1: any, key2: any, schema: any) => {
      return key1 === "Link To Flow" && key2 !== "Video"
        ? schema
            .required()
            .test(
              "notEmpty",
              "Field is required",
              (value: any) => value && Object.keys(value).length > 0,
            )
        : schema;
    },
  ),
  sub_flow: Yup.mixed("Field is required").when(
    ["position", "View_options"],
    (key1: any, key2: any, schema: any) => {
      return key1 === "Link To Flow" && key2 !== "Video"
        ? schema
            .required()
            .test(
              "notEmpty",
              "Field is required",
              (value: any) => value && Object.keys(value).length > 0,
            )
        : schema;
    },
  ),
});

export const WhatsAppConfigValidations = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
  customer_company_name: Yup.string().required().label("Company Name"),
  company_registred_address: Yup.string().required().label("Company Address"),
  company_website: Yup.string().required().label("Company Website"),
  company_facebook_business_manage_ID: Yup.string()
    .required()
    .label("Facebook Business ID"),
  display_name: Yup.string().required().label("Display Name"),
  is_business_verified_on_facebook: Yup.string()
    .required()
    .label("Facebook Verification"),
  migrating_from_other_whatsapp_api_provider: Yup.string()
    .required()
    .label("WhatsApp Migration"),
  is_two_factor_authentication_removed_from_other_vendor: Yup.string()
    .required()
    .label("Two Factor"),
});

export const AddContactFormSchema = (f: any, valid: any) => {
  return Yup.object().shape(
    f?.reduce((schema: any, field: any) => {
      const fieldName = field?.name;
      if (field.required) {
        if (field.name === "first_name") {
          return {
            ...schema,
            [fieldName]: Yup.string()
              .matches(/^[A-Za-z ]*$/, "Please enter valid name")
              .required("First Name is required")
              .label("First Name"),
          };
        }
        if (field.type === "phone") {
          return {
            ...schema,
            [fieldName]: Yup.string()
              .min(1, "Invalid Mobile format")
              .max(19)
              .required()
              .label(field.label)
              .test("strongPhoneTest", null, (value: any, ctx: any) => {
                let mobileCountryFormat: any =
                  ctx?.parent?.[`mobileCountryFormat${fieldName}`];
                if (mobileCountryFormat?.format === true) {
                  return true;
                }
                let format: any = mobileCountryFormat?.format
                  ?.replaceAll(".", "0")
                  ?.replaceAll(" ", "")
                  ?.replaceAll("(", "")
                  ?.replaceAll(")", "")
                  ?.replaceAll("-", "")
                  ?.replaceAll("+", "");

                let dialCodeLength: any = mobileCountryFormat?.dialCode?.length;
                let formatWithoutDialCode: any =
                  format?.substring(dialCodeLength);
                let valueWithoutDialCode: any =
                  value?.substring(dialCodeLength);

                if (
                  formatWithoutDialCode?.length == valueWithoutDialCode?.length
                ) {
                  let count = 0;
                  for (var i = 0; i < valueWithoutDialCode?.length; i++) {
                    if (
                      valueWithoutDialCode.charAt(i) == 0 ||
                      valueWithoutDialCode.charAt(i) == "0"
                    ) {
                      count = count + 1;
                    }
                  }
                  if (formatWithoutDialCode?.length == count) {
                    return new Yup.ValidationError(
                      `Please enter valid Mobile number.`,
                      null,
                      fieldName,
                    );
                  } else {
                    return true;
                  }
                } else {
                  return new Yup.ValidationError(
                    `Please enter valid Mobile format for ${
                      mobileCountryFormat?.name || ""
                    } ${formatWithoutDialCode?.length || ""} digits required`,
                    null,
                    fieldName,
                  );
                }
              }),
          };
        } else if (field.type === "email") {
          return {
            ...schema,
            [fieldName]: Yup.string().email().required().label(field.label),
          };
        } else if (field.type === "multiselect") {
          return {
            ...schema,
            [fieldName]: Yup.array()
              .min(1, "Please select atleast one option")
              .required("Please select one option")
              .label(fieldName),
          };
        } else if (
          field.type === "dropdown" ||
          field.type === "autocomplete" ||
          field.type === "radio"
        ) {
          if (valid[fieldName]) {
            return {
              ...schema,
              [fieldName]: Yup.object().label(field?.label).notRequired(),
            };
          } else {
            return {
              ...schema,
              [fieldName]: Yup.object().required().label(field?.label),
            };
          }
        } else {
          if (
            field?.name === "country_region" ||
            field?.name === "state_region" ||
            field?.name === "city"
          ) {
            return {
              ...schema,
              [fieldName]: Yup.object().required().label(field?.label),
            };
          } else {
            return {
              ...schema,
              [fieldName]: Yup.string().required().label(field.label),
            };
          }
        }
      } else {
        if (field.type === "phone" && field?.name === "mobile_phone_number") {
          return {
            ...schema,
            [fieldName]: Yup.string()
              .min(1, "Invalid Mobile format")
              .max(19)
              .optional()
              .label(field.label)
              .test("strongPhoneTest", null, function (value: any, ctx: any) {
                const parent: any = ctx.parent;
                if (!value) return true; // If no value, it's optional and valid
                let result: boolean = true;

                let mobileCountryFormat =
                  parent?.mobileCountryFormatmobile_phone_number;
                let iso2 = mobileCountryFormat?.iso2 || "in";

                const appendingPlusSignToMobileValue = "+" + value;
                if (iso2 || mobileCountryFormat) {
                  result = ValidateCountryMobileNumber(
                    iso2,
                    appendingPlusSignToMobileValue,
                  );
                }

                if (!result) {
                  return new Yup.ValidationError(
                    `Please enter a valid Mobile number for ${mobileCountryFormat?.name}`,
                    null,
                    "mobile_phone_number",
                  );
                }
                return true;
              }),
          };
        } else if (
          field.type === "phone" &&
          field?.name !== "mobile_phone_number"
        ) {
          return {
            ...schema,
            [fieldName]: Yup.string()
              .min(1, "Invalid Mobile format")
              .max(19)
              .optional()
              .label(field.label)
              .test("strongPhoneTest", null, function (value: any, ctx: any) {
                const parent: any = ctx.parent;
                if (!value) return true; // If no value, it's optional and valid
                let result: boolean = true;

                let mobileCountryFormat =
                  parent?.mobileCountryFormatwhatsapp_phone_number;
                let iso2 = mobileCountryFormat?.iso2 || "in";

                const appendingPlusSignToMobileValue = "+" + value;
                if (iso2 || mobileCountryFormat) {
                  result = ValidateCountryMobileNumber(
                    iso2,
                    appendingPlusSignToMobileValue,
                  );
                }
                if (!result) {
                  return new Yup.ValidationError(
                    `Please enter a valid Mobile number for ${mobileCountryFormat?.name}`,
                    null,
                    field?.name,
                  );
                }
                return true;
              }),
          };
        } else if (field.type === "multiselect") {
          return {
            ...schema,
            // [fieldName]: Yup.array().message("Please select")
          };
        } else if (field.type === "landline") {
          return {
            ...schema,
            [fieldName]: Yup.string()
              .min(6)
              .max(12)
              .optional()
              .label("Landline"),
            [`landlineExtension${fieldName}`]: Yup.string()
              .min(1)
              .max(5)
              .optional()
              .label("Code"),
          };
        } else if (field.type === "dropdown") {
          return {
            ...schema,
            [fieldName]: Yup.mixed().label("Dropdown"),
          };
        } else if (field.type === "radio") {
          return {
            ...schema,
            [fieldName]: Yup.object().required().label(field?.label),
          };
        } else {
          return {
            ...schema,
            [fieldName]: Yup.string().label(field.label),
          };
        }
      }
    }, {}),
  );
};

export const upDateContactFormSchema = (f: any) => {
  return Yup.object().shape(
    f?.reduce((schema: any, field: any) => {
      const fieldName = field?.name;
      if (field.type === "phone") {
        return {
          ...schema,
          [fieldName]: Yup.string()
            .min(1, "Invalid Mobile format")
            .max(19)
            .required()
            .label(field.label)
            .test("strongPhoneTest", null, (value: any, ctx: any) => {
              let mobileCountryFormat: any =
                ctx?.parent?.[`mobileCountryFormat${fieldName}`];

              let format: any = mobileCountryFormat?.format
                ?.replaceAll(".", "0")
                ?.replaceAll(" ", "")
                ?.replaceAll("(", "")
                ?.replaceAll(")", "")
                ?.replaceAll("-", "")
                ?.replaceAll("+", "");
              let dialCodeLength: any = mobileCountryFormat?.dialCode?.length;
              let formatWithoutDialCode: any =
                format?.substring(dialCodeLength);
              let valueWithoutDialCode: any = value?.substring(dialCodeLength);

              if (
                formatWithoutDialCode?.length == valueWithoutDialCode?.length
              ) {
                let count = 0;
                for (var i = 0; i < valueWithoutDialCode?.length; i++) {
                  if (
                    valueWithoutDialCode.charAt(i) == 0 ||
                    valueWithoutDialCode.charAt(i) == "0"
                  ) {
                    count = count + 1;
                  }
                }
                if (formatWithoutDialCode?.length == count) {
                  return new Yup.ValidationError(
                    `Please enter valid Mobile number.`,
                    null,
                    fieldName,
                  );
                } else {
                  return true;
                }
              } else {
                return new Yup.ValidationError(
                  `Please enter valid Mobile format for ${mobileCountryFormat?.name} ${formatWithoutDialCode?.length} digits required`,
                  null,
                  fieldName,
                );
              }
            }),
        };
      } else if (field.type === "email") {
        return {
          ...schema,
          [fieldName]: Yup.string().email().required().label(field.label),
        };
      } else if (field.type === "dropdown") {
        return {
          // ...schema,
          // [fieldName]: Yup.object().when("isEmailDisabled", {
          //   is: false,
          //   then: Yup.string().required(`${field.label} field is required.`),
          // }),
          ...schema,
          [fieldName]: Yup.object().required().label(field.label),
        };
      } else {
        return {
          ...schema,
          [fieldName]: Yup.string().required().label(field.label),
        };
      }
    }, {}),
  );
};

export const WebSiteAndSitemapUrlValidations = Yup.object().shape({
  crawlUrl: Yup.string()
    .matches(URLREGEX, "Enter correct url!")
    .required("Please enter  url"),
});
// create marketing list schema
export const CreateMarketingListValidations = Yup.object().shape({
  name: Yup.string()
    .ensure()
    .required()
    .min(2, "List name  is a required")
    .label("List name"),
});
export const FAQAnsweredValidation = Yup.object().shape({
  question: Yup.string()
    .required("Question is required")
    .min(1, "Question must be at least 1 characters")
    .label("Question"),
  faqQuestion: Yup.mixed().required(),
  journey: Yup.mixed().nullable(),
  text: Yup.string(),
  image: Yup.mixed().notRequired(),
  document: Yup.mixed().notRequired(),
  audio: Yup.mixed().notRequired(),
  video: Yup.mixed().notRequired(),
  link: Yup.string()
    .matches(URLREGEX, "Enter a valid website")
    .label("Link")
    .notRequired(),
});

// bulk contact action

export const BulkAddTolList = Yup.object().shape({
  addtolist: Yup.object()
    .required("Please select contact list")
    .label("Contact List"),
});

export const BulkAddTags = Yup.object().shape({
  tags: Yup.mixed().required("Please select contact list").label("Tags"),
});

export const BulkAssignTo = Yup.object().shape({
  assign_to: Yup.object()
    .required("Please select assign to")
    .label("Assign To"),
});

// auto assignment
export const AutoAssignmentSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .max(40)
    .required("Rule Name is required")
    .label("Rules name"),
  // owner: Yup.mixed().required("Please select owner list").label("Owner List"),
  owner: Yup.array()
    .min(1, "Atleast 1 Owner is name is required ")
    .required("Please select owner list")
    .label("Owner"),
});

// Contact Properties
export const AddSectionValidation = Yup.object().shape({
  label: Yup.string()
    .min(3, "Stage name must be at least 3 characters")
    .max(100, "Stage name can be maximum 100 characters")
    .required("Field label is required")
    .label("Field label"),
});

export const LifecycleFormCreateSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Stage name must be at least 3 characters")
    .max(24, "Stage name can be maximum 24 characters")
    .required("Stage name is required"),
  status: Yup.array().of(
    Yup.object().shape({
      label: Yup.string()
        .min(3, "Status name must be at least 3 characters")
        .max(24, "Status name can be maximum 24 characters")
        .required("status name is required"),
    }),
  ),
});

export const CreateFormsSchema = Yup.object().shape({
  form_name: Yup.string()
    .min(2)
    .max(240)
    .required("Form Name is required")
    .label("Form Name"),
  form_description: Yup.string().min(2).max(100).label("Form Description"),
  form_config: Yup.string()
    .required()
    .max(100000000, "Source Description must not exceed 100000000 characters.")
    .label("Source Description"),
});
export const scheduleSchema = Yup.object().shape({
  resolved_body_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .min(2, "Field is a required field")
          .max(60)
          .required()
          .label("Field"),
        value: Yup.string().ensure().min(1).max(60).required().label("Value"),
      }),
    )
    .label("Body variables"),
  resolved_header_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .max(60)
          .label("Field")
          .when(["type"], (key1: any, schema: any) => {
            return key1 !== "TEXT"
              ? schema.optional().notRequired()
              : schema.required().min(2, "Field is a required field");
          }),
        value: Yup.mixed()
          .required()
          .label("Value")
          .test("mediavaluetest", null, (currentValue: any, ctx: any) => {
            if (currentValue && Object?.keys(currentValue)?.length < 1) {
              return new Yup.ValidationError(
                `Please enter valid Mobile number.`,
                null,
                "resolved_header_variables",
              );
            }
            return true;
          }),
      }),
    )
    .max(1)
    .label("Header variables"),
  resolved_buttons_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          // .min(2, "Field is a required field")
          .max(60)
          // .required()
          .label("Field"),
        value: Yup.string().ensure().min(1).max(60).required().label("Value"),
      }),
    )
    .max(10)
    .nullable()
    .label("Button variables"),
});

export const UpdateLifeCycleStageSchema = Yup.object().shape({
  lifecycle_stage: Yup.mixed().required().label("Lifecycle stage"),
  lifecycle_status: Yup.mixed().required().label("Lifecycle status"),
  lost_reason: Yup.mixed()
    .test(
      "requiredWhenClosedLost",
      "Lost reason is required",
      function (value: any, ctx: any) {
        const lifecycle_status = ctx?.parent?.lifecycle_status;
        if (lifecycle_status?.type === "closed_lost") {
          return !!value;
        }
        return true;
      },
    )
    .label("Lost reason"),
  other_lost_reason: Yup.string()
    .test(
      "requiredWhenOtherLostReason",
      "Other lost reason is required",
      function (value: any, ctx: any) {
        const lifecycle_status = ctx?.parent?.lifecycle_status;
        const lost_reason = ctx?.parent?.lost_reason;
        if (
          lost_reason?.label === "Other" &&
          lifecycle_status?.type === "closed_lost"
        ) {
          return !!value;
        }
        return true;
      },
    )
    .label("Other lost reason"),
});

export const singleWhatsappBroadcastSchema = Yup.object().shape({
  sender_field: Yup.mixed().required().label("Sender field"),
  sender_field_value: Yup.string().required().label("Sender value"),
  template: Yup.mixed()
    .required()
    .test(
      "templateRequired",
      "Template is required.",
      function (value: any, ctx: any) {
        // const parent  = ctx?.parent;
        if (!value?.id) {
          return !!value;
        }
        return true;
      },
    )
    .label("Template"),
  resolved_body_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .min(2, "Field is a required")
          .max(60)
          .required()
          .label("Field"),
        value: Yup.string()
          .ensure()
          .min(1, "Field is a required")
          .max(60)
          .required()
          .label("Value"),
      }),
    )
    .label("Body variables"),
  resolved_header_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .max(60)
          .label("Field")
          .when(["type"], (key1: any, schema: any) => {
            return key1 !== "TEXT"
              ? schema.optional().notRequired()
              : schema.required().min(2, "Field is a required");
          }),
        value: Yup.mixed()
          .required()
          .label("Value")
          .test(
            "mediavaluetest",
            "Field is required",
            (currentValue: any, ctx: any) => {
              if (currentValue && Object?.keys(currentValue)?.length < 1) {
                return new Yup.ValidationError(
                  `Field is required`,
                  null,
                  "resolved_header_variables",
                );
              }

              return true;
            },
          ),
      }),
    )
    .max(1)
    .label("Header variables"),
});
export const singleWhatsappBroadcastSchemaOptInOptOut = Yup.object().shape({
  resolved_body_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .min(2, "Field is a required")
          .max(60)
          .required()
          .label("Field"),
        value: Yup.string()
          .ensure()
          .min(1, "Field is a required")
          .max(60)
          .required()
          .label("Value"),
      }),
    )
    .label("Body variables"),
  resolved_header_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .max(60)
          .label("Field")
          .when(["type"], (key1: any, schema: any) => {
            return key1 !== "TEXT"
              ? schema.optional().notRequired()
              : schema.required().min(2, "Field is a required");
          }),
        value: Yup.mixed()
          .required()
          .label("Value")
          .test(
            "mediavaluetest",
            "Field is required",
            (currentValue: any, ctx: any) => {
              if (currentValue && Object?.keys(currentValue)?.length < 1) {
                return new Yup.ValidationError(
                  `Field is required`,
                  null,
                  "resolved_header_variables",
                );
              }

              return true;
            },
          ),
      }),
    )
    .max(1)
    .label("Header variables"),
});
export const singleSmsBroadcastSchema = Yup.object().shape({
  sender_field: Yup.mixed().required().label("Sender field"),
  sender_field_value: Yup.string().required().label("Sender value"),
  sms_sender_id: Yup.mixed().required().label("Sender ID"),
  template: Yup.mixed()
    .required()
    .test(
      "templateRequired",
      "Template is required.",
      function (value: any, ctx: any) {
        // const parent  = ctx?.parent;
        if (!value?.id) {
          return !!value;
        }
        return true;
      },
    )
    .label("Template"),
  resolved_body_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .min(2, "Field is a required")
          .max(60)
          .required()
          .label("Field"),
        value: Yup.string()
          .ensure()
          .min(1, "Field is a required")
          .max(60)
          .required()
          .label("Value"),
      }),
    )
    .label("Body variables"),
  resolved_header_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .max(60)
          .label("Field")
          .when(["type"], (key1: any, schema: any) => {
            return key1 !== "text"
              ? schema.optional().notRequired()
              : schema.required().min(2, "Field is a required");
          }),
        value: Yup.mixed()
          .required()
          .label("Value")
          .test(
            "mediavaluetest",
            "Field is required",
            (currentValue: any, ctx: any) => {
              if (currentValue && Object?.keys(currentValue)?.length < 1) {
                return new Yup.ValidationError(
                  `Field is required`,
                  null,
                  "resolved_header_variables",
                );
              }

              return true;
            },
          ),
      }),
    )
    .max(1)
    .label("Header variables"),
});

export const singleEmailBroadcastSchema = Yup.object().shape({
  sender_field: Yup.mixed().required().label("Sender field"),
  sender_field_value: Yup.string().required().label("Sender value"),
  // template: Yup.mixed().required().test('templateRequired', 'Template is required.', function (value: any, ctx: any) {
  //   console.log("🚀 ~ value:", value)
  //   // const parent  = ctx?.parent;
  //   if (!value?.id) {
  //     return !!value;
  //   }
  //   return true;
  // }).label("Template"),
  choose_template_type: Yup.object().label("Choose template type"),
  template: Yup.object().when("choose_template_type.value", {
    is: "choose_email_template",
    then: Yup.object()
      .required("Template is required")
      .typeError("Template is required")
      .nullable(false),
    otherwise: Yup.object().nullable(),
  }),
  from_name: Yup.string().required().label("From name"),
  subject: Yup.string().required().label("Subject"),
  reply_to: Yup.string().email().label("Reply to"),
  footer: Yup.mixed().required().label("Footer"),
  from_email: Yup.mixed().required().label("From email"),
  attachement: Yup.mixed().label("Attachment"),
});
export const renameContactViewSchema = Yup.object().shape({
  name: Yup.string().required().min(1).max(30).label("Name"),
});
export const whatsappMangeProfileSchema = Yup.object().shape({
  websites: Yup.string()
    .label("Website")
    .transform((curr: any, val: any) => (val === "" ? null : curr))
    .matches(WEB_REGEX, "Enter a valid url")
    .matches(WEB_REGEX, "Enter a valid website"),
  display_name: Yup.string().min(3).label("Name"),
  email: Yup.string().email().label("email"),
});
